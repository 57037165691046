/*
Outer container will determine block sizes:
<div className="outer"><div className="loader-column" /></div>
.outer {
  height: 60px;
  width: 20px;
}
*/
@include keyframes(column) {
  0%,
  80%,
  100% {
    box-shadow: 0 0 $blue;
  }

  40% {
    box-shadow: 0 -3em $blue;
  }
}

.loader-column,
.loader-column::before,
.loader-column::after {
  animation: column 1.5s infinite ease-in-out;
  background: $blue;
  width: 100%;
  height: 100%;
}

.loader-column::before,
.loader-column::after {
  position: absolute;
  content: '';
}

.loader-column::before {
  left: -130%;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-column::after {
  left: 130%;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-column {
  content: '';
  text-indent: -9999em;
  position: relative;
}

.loader-general {
  margin: 6em auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(61, 133, 209, 0.2);
  border-right: 1.1em solid rgba(61, 133, 209, 0.2);
  border-bottom: 1.1em solid rgba(61, 133, 209, 0.2);
  border-left: 1.1em solid $blue;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation: general 1.1s infinite linear;
}

.loader-general,
.loader-general::after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader-error {
  text-align: center;
  position: relative;
}

@include keyframes(general) {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
