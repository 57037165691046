@import 'styles/variables';
@import 'styles/mixins';

.report {
  padding-top: 10px;

  @include desktop() {
    padding-top: 20px;
  }

  :global(.nx-spinner-svg) {
    z-index: 4;
  }
}

.title {
  margin: 0;
}

.dateRange {
  margin: 15px 0;
}

.legend {
  display: flex;
}

.content {
  position: relative;
  min-height: 300px;

  :global(.report-table) {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.chartLoading {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 3;
  }
}

.filters {
  margin-top: 20px;

  :global {
    .dropzone {
      border: 0;
    }

    .nx-accordion-item {
      &__heading {
        border-bottom: 1px solid #f2f2f2;

        &:focus {
          background: 0;
        }

        &:hover {
          background: rgba(180, 180, 180, 0.1);
        }
      }

      &__main {
        border-bottom: 0;
      }
    }
  }
}