.save-schedule,
.scheduled {
  .search,
  .new-report,
  .new-dashboard,
  .new-query {
    position: relative;
    top: -10px;
    font-size: 16px;
  }

  .new-report,
  .new-dashboard,
  .new-query {
    margin-left: 20px;
    font-size: 18px;
    height: 19px;
  }

  .new-report-plus,
  .new-dashboard-plus,
  .new-query-plus {
    height: 15px;
    width: 15px;
    position: relative;
    left: 8px;
    top: 1px;

    path {
      fill: white !important;
    }
  }

  .data-table {
    margin-top: 20px;
    margin-bottom: 50px;

    .pointer {
      &:hover * {
        background-color: $hover-bg;
      }
    }
  }
}

.weekday-checkboxes {
  .weekday-inner {
    display: inline-block;
  }

  label {
    font-size: 16px;
    width: 40px;
    text-align: center;
    display: inline-block;
    color: #888;
    cursor: pointer;

    @extend %noselect;

    input {
      display: block;
      margin: auto;
      margin-top: 10px;
    }
  }

  .hours,
  .minutes {
    margin: 0 !important;
    width: 20px;
    outline: none;
  }

  .colon {
    margin: 0 7px;
    font-size: 0.9em;
    color: #c0c0c0;
    font-weight: 300;
  }

  select {
    font-size: 0.8em;
    padding-right: 30px;
    background: url('../img/arrow.png');
    background-position: 90% 50%;
    background-repeat: no-repeat;
  }

  .weekday-time {
    vertical-align: bottom;
    display: inline-block;
  }

  .date {
    width: 120px;
  }

  .meridiem {
    font-size: 1em;
    margin-left: 6px;
    border: none;
  }

  input[type=text] {
    border: 1px solid $input-border;
    font-size: 16px;
    padding: 10px;
  }
}

.schedule-form {
  .today {
    color: #777;
    font-size: 20px;
    font-style: italic;
    padding-right: 10px;
  }

  .at {
    margin: 0 10px;
    font-size: 0.9em;
    color: #c0c0c0;
    font-weight: 300;
  }

  .email-addresses {
    width: 500px;
  }

  .email-tip {
    display: block;
    padding-top: 10px;
    font-style: italic;
    color: #999;
    font-size: 16px;
  }

  .buttons .btn {
    width: 80px;
  }

  .buttons.pull-right div {
    margin-right: 12px;
  }

  .schedule-form-title {
    margin-bottom: 35px;
  }

  .schedule-name {
    width: 400px;
    padding-right: 100px;
  }

  .row {
    margin-bottom: 40px;
  }

  .report-name {
    width: 250px;

    .report-name-title {
      display: flex;

      .report-name-tip {
        margin-left: 5px;
      }
    }

    select {
      font-size: 16px;
    }
  }

  .datetime .label {
    margin: 0;
  }

  .starts,
  .ends,
  .distribution,
  .run-until {
    width: 500px;

    select {
      width: 70px;
    }
  }

  .datetime .btn {
    width: 140px;
  }

  .weekday-checkboxes {
    margin-top: -15px;
  }

  .divider {
    border-bottom: 1px solid $input-border;
    margin: 20px 0;
    height: 1px;
    width: 100%;
  }
}

.schedule-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;

  h1 {
    margin: 0;
  }

  .btn,
  .search {
    position: inherit;
  }
}
