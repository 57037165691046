@import '@nextiva/next-ui/styles/colors';

.title {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 6px 10px;
  color: color(charcoal);
}

.icon {
  margin-right: 10px;
  height: 18px;
  width: 18px;

  path {
    fill: color(charcoal);
  }
}