table.standard-table {
  table-layout: auto;
  font-size: 16px;
  width: 100%;

  thead {
    th {
      background-color: #555;
      padding: 11px 0;
      color: white;
      font-weight: 300;
      border-right: 1px solid white;
      font-size: 14px;
    }

    th:first-child {
      padding-left: 25px;
      text-align: left;
      width: 25%;
    }
  }

  tbody {
    border: 1px solid $border;

    sup {
      padding-left: 2px;
    }

    td {
      border-right: 1px solid $border;
      color: #666;
      
    }

    td:first-child {
      padding: 10px 0 10px 20px;
    }

    td:not(:first-child) {
      text-align: center;
    }

    tr:nth-child(even) {
      background-color: #fbfbfb;
    }
  }
}

table.custom-table {
  table-layout: fixed;

  th {
    width: 200px;
  }

  th:first-child {
    padding-left: 25px;
    text-align: left;
    width: 200px !important;
  }
}

table.data-table {
  table-layout: auto;
  width: 100%;

  th {
    text-align: left;
    font-size: 18px;
    padding: 20px 0 20px 20px;
    padding-bottom: 15px;
    color: #aaa;
    font-weight: 300;

    @extend %noselect;

    > span {
      cursor: pointer;
    }
  }

  td {
    color: #777;
    font-size: 20px;
    background-color: #fcfcfc;
    
    .underline:hover {
      text-decoration: underline;
    }
  }

  tbody tr {
    border: 1px solid #ddd;
  }

  .arrow {
    cursor: pointer;
    margin-left: 5px;
    top: 4px;
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  .arrow-left,
  .arrow-right {
    cursor: pointer;
    width: 18px;
    height: 18px;
    padding: 0;
    top: 2px;
    vertical-align: top;
  }

  .arrow-right {
    margin-left: 10px;
    margin-right: -5px;
  }

  .arrow-left {
    margin-right: 10px;
  }
}

.scorecards-table {
  width: 100%;
}

.table-links {
  div {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 18px;
    cursor: pointer;
    padding-left: 14px;
    padding-right: 20px;
    text-decoration: underline;
    color: #333;

    @extend %noselect;
  }
}

.table-container {
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
  }
}

.call-prop-filter {
  width: 180px;
}

.call-log-table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;

  th {
    border: 1px solid $input-border;
    border-top: 0;
    font-weight: 300;
    color: #666;
    text-align: left;
    padding: 106px;
    font-size: 16px;
  }

  th:first-child {
    width: 1%;
    text-align: center;
  }

  td {
    border-left: 1px solid $input-border;
    border-right: 1px solid $input-border;
    padding: 6px;
    font-size: 14px;
  }

  td:first-child {
    img {
      position: relative;
      top: 3px;
      width: 16px;
      height: 16px;
    }
  }

  tbody tr:hover {
    background-color: #fcfcfc;
    
  }

  .icon-custom-volume-high {
    cursor: pointer;
  }
}

.call-log-wrapper {
  position: relative;
  min-height: 300px;

  .call-log-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 30px;
    width: 100%;

    .call-log-count-text {
      font-size: 20px;
    }

    .dropzone-container .page-controls {
      color: #666;
      font-size: 20px;
    }
  }

  .limit-select {
    margin-left: 20px;
    margin-right: 20px;
    width: 70px;
    display: inline-block;
  }

  .call-log-arrow {
    width: 16px;
    height: 16px;
    cursor: pointer;
    float: right;
  }

  .th-bold {
    color: #444;

    path {
      fill: #666;
    }
  }

  .call-log-sortable-header {
    cursor: pointer;
  }

  .call-log-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;

    .dropzone-container .arrow-right {
      top: 0;
    }
  }

  .dropzone-container {
    display: inline !important;
  }

  .dropzone-container .page-controls {
    display: inline !important;
    color: #666;
  }

  .call-log-page-number {
    font-size: 15px;
  }

  .call-log-export {
    float: right;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
    padding-top: 10px;
  }

  .call-log-search {
    margin-left: 15px;
    vertical-align: middle;
  }
}

.call-log-popover-content {
  background-color: white;
  padding: 20px;
  padding-top: 0;
  width: 300px;
  height: 170px;

  input[type="text"] {
    box-sizing: border-box;
    width: 100%;
  }

  button {
    margin-top: 20px;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .error-container {
    height: 20px;
  }

  .error-text {
    color: #fd2926;
  }

  .buttons {
    .cancel {
      margin-right: 15px;
    }
  }
}

.call-log-container {
  position: relative;
  margin-bottom: 20px;
  min-height: 250px;

  @include tablet() {
    overflow: auto;
  }
}

.fattable-h-scrollbar {
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.disable-h-scroll {
  .fattable-h-scrollbar {
    visibility: hidden !important;
  }
}

.disable-v-scroll {
  .fattable-v-scrollbar {
    visibility: hidden !important;
  }
}

.fattable-h-scrollbar > div {
  padding: 0 !important;
}

.fattable-v-scrollbar {
  padding: 0;
  position: absolute;
  background-color: transparent;
  width: auto;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.fattable-v-scrollbar > div {
  padding: 0 !important;
}

.fattable * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.fattable {
  overflow: hidden;
}

.fattable ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.fattable ::-webkit-scrollbar-track {
  -webkit-border-radius: px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.02);
}

.fattable ::-webkit-scrollbar-thumb {
  -webkit-border-radius: px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

.fattable ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

.fattable ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.03);
}

.fattable-moving {
  cursor: move;
}

.fattable-viewport {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.fattable-viewport > div {
  padding: 2px;
  position: absolute;
  font-size: 15px;
  font-family: sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fattable-body-container {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  width: 100%;
}

.fattable-header-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100px;
}

.fattable-header-container .fattable-viewport {
  height: 100%;
}

.fattable-header-container .fattable-viewport > div {
  height: 100%;
}

.fat-table {
  position: relative;
  height: 295px;
  width: 100%;
}

.fattable-header-container {
  background-color: white;
  color: #eee;
}

.fattable-header-container > div > div {
  border-right: solid 1px #555;
  padding: 10px;
}

.fattable-header-container .fattable-viewport > div {
  background-color: #666;
}

.fattable-body-container > div > div {
  border-right: solid 1px #fff;
  padding: 10px;
}

.fattable-body-container .first-column {
  border-left: 1px solid #d3d3d3;
}

.fattable-body-container > div > div.even {
  color: #222;
  background-color: rgba(0, 0, 0, 0.06);
}

.fattable-body-container > div > div.pending {
  background-color: rgba(255, 0, 0, 0.06);
}

.fattable-body-container .fattable-viewport div {
  background-color: white;
  border-right: 1px solid $input-border;
  border-bottom: 1px solid $input-border;
}

.sample-table-overlay {
  position: absolute;
  font-size: 140px;
  font-weight: 800;
  z-index: 10;
  color: #333;
  opacity: 0.4;
  top: 300px;

  @extend %noselect;

  transform: rotate(25deg);
  left: 125px;

  @include desktop() {
    font-size: 120px;
  }

  @include tablet() {
    left: 25px;
    font-size: 96px;
  }
}
