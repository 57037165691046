@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/colors';
@import '~@nextiva/next-ui/styles/colors';

$list-height: 55px;

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid color(mercury);
}

.list {
  display: flex;
  position: relative;
  top: 1px;
  height: $list-height;
  margin: 0;
  border: 0;
  padding: 0;

  li:first-child .tab {
    padding-left: 0;
  }
}

.tab {
  display: block;
  margin-right: 0;
  height: 55px;
  padding: 0 25px;
  line-height: 55px;
  font-size: 22px;
  font-weight: 300;
  color: color(graphite);
  text-decoration: none;
  box-sizing: border-box;

  @include desktop() {
    padding: 0 15px;
    font-size: 18px;
  }

  @include tablet() {
    padding: 0 10px;
    font-size: 16px;
  }

  &:hover {
    color: color(nextiva-blue);
  }

  &.active {
    color: color(nextiva-blue);
    border-bottom: 2px solid color(nextiva-blue);
  }
}

.link {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-right: 10px;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: auto;
  min-height: 300px; // for IE11
  padding-top: 10px;
}
