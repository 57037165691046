@import 'styles/mixins';

.chart-container {
  padding: 25px;

  .chart-empty {
    position: relative;
    height: 500px;
  }

  &.border {
    border: 1px solid $border;
  }

  .loader-error {
    @extend %noselect;
    @extend %center-all;

    flex-direction: column;

    @include center-position();

    img {
      cursor: pointer;
      width: 300px;
      display: block;
      margin-bottom: 20px;
    }
  }
}

.dropzone-container,
.paginate-controls {
  @extend %noselect;

  .dropzone {
    padding-left: 10px;
    min-height: 270px;
  }

  .page-controls {
    margin-bottom: 10px;

    .pull-right {
      margin-top: 10px;
    }
  }

  .arrow-left,
  .arrow-right {
    cursor: pointer;
    width: 18px;
    height: 18px;
    padding: 0 10px;
    top: 2px;
    vertical-align: top;
  }

  .hidden {
    visibility: hidden;
  }

  .toggle-label {
    display: block;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
  }

  th {
    position: relative;
  }

  .data-table-pagination {
    position: absolute;
    right: 0;
    top: 20px;
    vertical-align: top;

    & > * {
      vertical-align: top;
    }
  }

  .dropzone-limit-info {
    margin-left: 15px;

    &.dropzone-limit-full {
      color: #f00;
    }
  }
}

.chart-header {
  border-bottom: 1px solid $border;
  padding-bottom: 20px;
  font-family: "lato";
  font-size: 16px;

  .pull-right a {
    display: inline-block;
    margin-left: 15px;
    font-size: 16px;
    line-height: -9px;
    height: 15px;
  }

  .popout-element {
    cursor: pointer;
    margin: 0 5px 0 10px;
  }

  > .pull-right > div {
    margin-left: 15px;
  }
}

.chart-title-container {
  font-size: 22px;
  width: 95%;
  margin: auto;
  margin-top: -25px;
  text-align: center;
  overflow: visible;

  .chart-title-bar {
    position: relative;
    top: 12px;
    border-bottom: 1px solid $input-border;
    width: 50%;
    height: 1px;

    &:nth-of-type(1) {
      margin-right: 20px;
    }

    &:nth-of-type(2) {
      margin-left: 20px;
    }
  }

  .chart-dropdown {
    width: 60px;
    padding: 0 7px;
  }

  .chart-title-contents {
    width: 250px;

    & > * {
      display: inline-block;
    }
  }
}

.chart-title {
  display: flex;
  position: relative;
  margin-bottom: 15px;

  .chart-title-expand {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    padding-left: 0;
    padding-right: 10px;

    path {
      fill: #ccc !important;
    }
  }
}

.custom-chart-container,
.chart-container,
.custom-report {
  .dates .divider {
    padding: 1px;
  }

  .dates {
    font-size: 15px;
  }

  .over {
    font-size: 15px;
    color: #999;
    padding: 10px;
  }
}

.custom-chart-items-container {
  .dragged-chart-item,
  li {
    padding: 8px;
    position: relative;
    margin: 5px 10px 5px 0;
    display: inline-block;
    width: 160px;
    padding-right: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet() {
      margin-left: 1%;
      margin-right: 1%;
      width: 48%;
      box-sizing: border-box;
    }
  }
}

.custom-chart-container {
  position: relative;
  background: white;
  border: 1px solid $border;
  padding: 0 10px;

  .chart {
    position: relative;
  }

  .custom-chart-header {
    padding: 15px 0;
    border-bottom: 1px solid $border;

    > * {
      display: inline-block;
    }

    > input {
      width: 400px;
      box-sizing: border-box;

      @include desktop() {
        width: 300px;
      }

      @include tablet() {
        width: 100%;
      }
    }
  }

  .date-control-container {
    display: flex;
    border-bottom: 1px solid $border;
    margin-bottom: 10px;
    padding: 15px 0;

    @include tablet() {
      display: block;
    }

    .date-range .real-time {
      margin: 0;
      width: 80px;
    }

    .chart-buckets {
      float: right;
    }
  }

  .svg-close {
    width: 12px;
    height: 20px;
    float: right;
    margin-right: 3px;

    path {
      fill: #bfbfbf !important;
    }
  }
}

.custom-chart-items-container {
  border: 3px dashed $border;
  margin: 15px 3px;
  padding: 5px 10px;

  &.hover {
    background-color: $hover-bg;
    border: 3px dashed $hover-color;
    cursor: pointer;
  }

  min-height: 65px;
}
