@import '../../styles/colors.scss';
.custom-table-Container{
    .table-content{
        .nx-table{
            width: 100%!important;
            & + div{
                width: 100%!important;
            }
        }
    }
    .nx-table__header{
        tr{
            th{
                .nx-table__header-text{
                    color: #000000c2;
                    font-size: 14px;
                    line-height: 22px;
                }
                &:first-child{
                    width: 20%!important;
                }
                &:nth-child(2){
                    width: 30%!important;
                }
                &:nth-child(3){
                    width: 25%!important;
                }
                &:nth-child(4){
                    width: 5%!important;
                }
                &:nth-child(5){
                    width: 15%!important;
                }

            }
        }
    }
    .nx-table__body{
        .nx-table__fixed-header-proxy{

            tr{
                th{

                    &:first-child{
                        width: 20%!important;
                    }
                    &:nth-child(2){
                        width: 30%!important;
                    }
                    &:nth-child(3){
                        width: 25%!important;
                    }
                    &:nth-child(4){
                        width: 5%!important;
                    }
                    &:nth-child(5){
                        width: 15%!important;
                    }

                }
            }
        }
    }
    .table-content{
        height: 50vh;
        overflow: hidden;
        div{
            height: 100%;
            span{
                &.quality{
                    font-weight: 900;
                }
                &.good{
                    color:$good
                }
                &.moderate{
                    color: $moderate;
                }
                &.poor{
                    color: $poor;
                }
            }

            table{
                .nx-table__cell-inner{
                    color: $table_content;
                    font-weight: 400;
                }
            }
        }
    }
}