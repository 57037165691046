.call-recording-modal {
  background-color: #fff;
  border: 1px solid #ccc;
  bottom: auto;
  left: 50%;
  max-height: 80vh;
  min-height: 10rem;
  outline: none;
  position: fixed;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
}

.call-recording-modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.call-recording-container {
  position: relative;
  width: 100%;
  background-color: white;

  h2,
  h3 {
    font-family: 'lato';
    font-weight: 300;
  }

  h3 {
    font-size: 1.2rem;
    color: #777;
  }

  .overlay {
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;

    .loader-general {
      position: relative;
      top: -35px;
      width: 5em;
      height: 5em;
    }
  }

  .horizontal-divider {
    border-top: 1px solid #ddd;
    margin-top: 2rem;
  }

  .email-container {
    margin-top: -0.6rem;
    padding: 0.6rem 1.2rem 1.2rem;

    .buttons {
      margin-right: 0;
      margin-bottom: 0;
    }

    .field-container {
      width: 100%;
    }

    .field-container input {
      width: calc(100% - 1.4rem);
    }

    input {
      margin-top: 0.8rem;
    }

    .email-tip {
      display: block;
      padding-top: 0.8rem;
      font-style: italic;
      color: #999;
      font-size: 1rem;
    }

    .email-errors {
      font-size: 1rem;
      margin-top: 10px;
      color: red;
    }
  }

  .header-info-container {
    position: relative;
    padding-left: 1.2rem;
    margin-bottom: 1rem;

    .close {
      border-radius: 50%;
      color: #999;
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: 1rem;
      top: 0.675rem;
      cursor: pointer;
      font-size: 0.9rem;
    }

    .sub-header {
      span {
        font-size: 1.1rem;
        font-weight: 300;
        color: #777;
      }

      .divider {
        font-size: 1.2rem;
        color: #999;
        font-weight: 100;
        display: inline-block;
        padding: 0 0.35rem;
      }

      .actions-container {
        display: inline-block;
        margin-left: 2rem;

        .icon-custom-envelope-fill {
          cursor: pointer;
          color: #ddd;
          font-size: 1.1rem;
        }

        .icon-custom-envelope-fill.active {
          color: #669ff4;
        }
      }
    }

    .header {
      h2 {
        display: inline-block;
      }

      .icon-custom-briefcase {
        background-color: #fafafa;
        border-radius: 50%;
        border: 1px solid #d1d1d1;
        color: #888;
        display: inline-block;
        line-height: 1.875rem;
        height: 2rem;
        text-align: center;
        width: 2rem;
        margin-right: 0.5rem;
      }

      .light {
        font-weight: 100;
      }

      .divider {
        font-size: 1.5rem;
        color: #999;
        font-weight: 100;
        display: inline-block;
        padding: 0 0.6rem;
      }
    }
  }

  .buttons {
    margin-right: 1.2rem;
    margin-bottom: 1.2rem;
    text-align: right;

    .btn {
      margin-left: 0.5rem;
    }
  }

  .notes-container {
    padding: 0 1.2rem 1.2rem;

    .notes {
      height: 3rem;
      width: calc(100% - 2rem);
      background-color: #f7f7f7;
      border: 1px solid #f1f1f1;
      padding: 1rem;
      resize: vertical;
    }
  }
}

.call-recording-missing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 56px;
  font-size: 1.5em;
  color: #777;
  text-align: center;
}

.call-recording-player {
  margin-left: 20px;
}

.recording-questions {
  padding: 0 1.2rem 1.2rem;

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
  }

  &__title {
    flex: 1;
    align-self: left;
    margin: 0;
  }

  &__questions-select {
    align-self: right;
    width: 350px;
  }

  &__questions {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__question {
    padding-bottom: 30px;
  }
}

.recording-no-questions-groups {
  padding: 10px 20px 30px;
  text-align: center;
  font-size: 18px;
}

.recording-question {
  &__title {
    font-weight: bold;
    padding-bottom: 15px;
  }

  &__text {
    cursor: pointer;
    padding-bottom: 15px;

    p {
      margin: 0;
    }
  }

  &__text-more {
    cursor: pointer;
    padding-bottom: 15px;
  }
}

.call-recording {
  &__tabs {
    list-style-type: none;
    padding: 0 20px;
  }

  &__tab {
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;

    &--selected {
      color: $blue;
    }
  }
}
