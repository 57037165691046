@import 'styles/colors';
@import 'styles/mixins';

.img {
  height: 100%;
  width: 100%;
}

.link {
  display: block;
  height: 35px;
  width: 200px;

  @include desktop() {
    height: 28px;
    width: 160px;
  }

  :global(.logo-svg-analytics) path {
    fill: $side-nav-default-color;
    transition: fill 1s ease-out;
  }

  &.active,
  &:hover {
    :global(.logo-svg-analytics) path {
      fill: $brand-yellow;
      transition: fill 0.3s ease-in;
    }
  }
}
