@import '@nextiva/next-ui/styles/colors';

.reportTable {
  display: flex;
  flex-direction: column;
  min-height: 70vh;

  .rows{
      width: 100%;
      font-weight: normal;
  }
  .user-from{
      display: flex;
      flex-direction: column;
      .user-name{
          color: #4B7FD0;
      }
  }
  .call-score{
    font-weight: bold;
      &.good{
          color: #02D2C1;
      }
      &.poor{
          color: #E84C2F;
      }
      &.moderate{
        color: #FF9910;
      }
  }
  table{
    border: 2px solid #EDEFF4;
    thead{
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      tr{
        th{
          box-sizing: border-box;
          height: 48px;
          margin: 0;
          border-right: 1px solid transparent;
          border-left: 1px solid transparent;
          font-family: 'lato', sans-serif;
          font-size: 13px;
          font-weight: 700;
          text-align: left;
          line-height: 24px;
          letter-spacing: 0;
          color: rgba(0, 0, 0, 0.54);
          &:first-child{
            padding-left: 20px;
          }
          span{
            &.sort-arrow-container{
              position: relative;
              margin-right: 13px;
               left:5%;
              .arrow-sort{
                position: absolute;
                width:10px!important;
                stroke: #758BA3;
                &.arrow-down{
                  top: 3px;
                }
                &.arrow-up{
                  bottom: 3px;
                }
              }
            }
          }
        }
      }
    }
    tbody{
      tr{
        border-bottom: #e5e5e5 1px solid;
        height: 85px;
        &:not(.expanded-container):hover{
          td{
            background: #E5E5E5;
            .expanded-row-container{
              background-color: #fff;
            }
          }
        }
        td{
          align-items: center;
          height: 44px;
          margin-right: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          &:first-child{
            padding-left: 20px;
          }
          &:nth-child(7){
            svg {
              vertical-align: middle;
              margin-right: 7px;
            }
          }
          &:last-child{
            span{
              display: flex;
              gap: 12px;
              align-items: center;
              svg{
                &.arrow{
                  width: 10px!important;
                  height: 5px;
                  stroke: #666666;
                }
              }
            }
          }
        }
        .expanded-row-container{
          width: 99%;
          margin:5px;
          border: 1px solid #EDEFF4;
          min-height: 250px;
          .headers{
            display: flex;
            flex-direction: row;
            border: 1px solid #E5E5E5;
            padding:8px 29px 0 29px;
            .tab-container{
              display: flex;
              gap: 45px;
              .tab{
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                padding-bottom: 10px;
                line-height: 24px;
                label{
                  cursor: pointer;
                  display: inherit;
                  color: #666666;
                  span{
                    gap: 4px;
                    &.good{
                      font-weight: bold;
                      color: #02D2C1;
                    }
                    &.poor{
                      font-weight: bold;
                        color: #E84C2F;
                    }
                    &.moderate{
                      font-weight: bold;
                      color: #FF9910;
                    }
                  }
                }
                &:first-child{
                  label{
                    &:first-child{
                      color: #4B7FD0;
                    }
                  }
                }
                &.active{
                  border-bottom: 2px solid #4B7FD0;
                }
              }
            }
            .button-group{
              margin-left: auto;
              display: flex;
              flex-direction: row;
              border-radius: 5px;
              align-items: center;
              justify-content: center;
              background-color: #EBEBEB;
              color: #AAAAAA;
              margin-top: 5px;
              margin-bottom: 6px;
              padding: 2px 4px;
              height: 36px;
              width: 133px;
              .button{
                font-size: 12px;
                line-height: 25px;
                cursor: pointer;
                height: 32px;
                width: 65px;
                padding: 0 7.5px;
                text-align: center;
                border-radius: 5px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                justify-content: center;
                &.active{
                  color: #666666;
                  background-color: #FFFFFF;
                }
              }
            }
          }
          .expand-contents{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &.summary{
              padding: 0 43px;
            }
              .content{
                display: flex;
                gap: 2em;
                flex-direction: row;
                .graph{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap:.5em;
                  label{
                    font-size: 14px;
                    font-weight: 600;
                    color: #666666;
                  }
                  .circle{
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 4px solid #C4C4C4;
                    color:#666666;
                    font-weight: bold;
                    font-size: 20px;
                    &.good{
                      border: 4px solid #02D2C1;
                      color: #02D2C1;
                    }
                    &.poor{
                        border: 4px solid #E84C2F;
                        color: #E84C2F;
                    }
                    &.moderate{
                      border: 4px solid #FF9910;
                      color: #FF9910;
                    }
                  }
                }
                .table{
                  width: 280px;
                  ul{
                    list-style: none;
                    li{
                      margin-bottom: 20px;
                      padding-bottom: 10px;
                      display: flex;
                      align-items: center;
                      font-size: 12px;
                      font-weight: 600;
                      color: #666666;
                      .empty{
                        font-size: 2em;
                        font-weight: bold;
                        margin-left: 5px;
                        line-height: 10px;
                      }
                      &:not(:last-child){
                        border-bottom: 1px solid #E5E5E5;
                      }
                    }
                  }
                }
              }
              // Details content
              .details-content-container{
                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(3, 1fr);
                width: 92.8vw;
                .details-content{
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid #E5E5E5;
                    position: relative;
                    padding: 12px 30px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 20px;
                    &:nth-last-child(-n+3){
                      border-bottom: none;
                      &:after{
                        content:'';
                        border-bottom: none;
                      }
                    }
                      &:after{
                        content:'';
                        border-bottom: .5px solid #E5E5E5;
                        position: absolute;
                        bottom: -1px;
                        width: 30px;
                        right: 0;
                      }
                    span{
                      display: initial;
                      justify-content: initial;
                    }
                }
              }
          }
        }
      }

    }
  }
  .arrow-down{
    transform: rotate(180deg)!important;
    transition:.5s;
  }
  .arrow-down{
    transition:.5s;
  }
  :global {
    .nx-textfield__input {
      border: 0;
    }

    .nx-table__row--clickable {
      cursor: default;
    }
  }
}

.volumeIcon {
  font-size: 16px;
  color: color(graphite);
  cursor: pointer;
  transition: color .3s ease-in-out;

  &:hover {
    color: #000;
  }
}

.directionIcon {
  width: 20px;
  height: 20px;

  :global(path) {
    fill: color(gray);
  }
}

.list {
  display: flex;
  flex-direction: column;
  flex: auto;

  & > div:last-child {
    display: flex;
    flex: auto;
  }
}

.table {
  width: 100%;
}
.download-csv{
  text-indent: -13333337px;
  width: 36px;
  height: 36px;
  padding: 0;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAQCAYAAADNo/U5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4M2Q5NDllYS1lMjE3LTQ3Y2QtYTU1Ni04MTQ3NmRjNWEwYWQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjJDMzE5OUFERDIwMTFFNEE3MUI4OTEyNEFENjEzQjQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjJDMzE5OTlERDIwMTFFNEE3MUI4OTEyNEFENjEzQjQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpiYjc1MTkyYi0yZTY4LTQ5YWUtYTgxOS01ZmM1OTI2YmVjNzYiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDoxODYwYzBkYy0xZDk5LTExNzgtYWI2Yi1lZWQ1ZWU2MWI5YmEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7MkIPxAAAAhUlEQVR42mIsKytjIBUwYRF7CMT/kfBDYjSRZdMg1FQCxO+gWAZNjQySHEgdAwtUogeIvwPxRCy2g/h8QJwPxFPRnQcSCAPiH2iafkDFp+Ly0zogdgPi91D+eyh/HaGAOAzEtkB8DEofRlfAgiOArgKxNVWDHGbTfxL0MFJkEyMpmgACDADF7RquHJcLdAAAAABJRU5ErkJggg==);
  background-color: #fff;
  background-position: center;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  margin: 8px 8px 0 10px;
  z-index: 999;
  position: absolute;
  top: -55px;
  right: 0;
  cursor: pointer;
}