@import 'styles/colors';
@import 'styles/mixins';

.accordion {
  .arrow path {
    fill: #aaa;
  }

  margin-top: 10px;

  &__button {
    transition: all 0.1s ease;
    position: relative;
    top: -3px;
    float: right;
    right: 0;
    width: 27px;
    height: inherit;
    background-color: transparent;
    border: none;

    .open & {
      transform-origin: 0% 50%;
      transform: rotateX(-180deg);
    }
  }

  &__section {
    border: 1px solid transparent;
    border-bottom: 1px solid $border;
    font-size: 18px;
    padding: 10px 5px 10px 7px;

    @include desktop() {
      font-size: 16px;
    }
  }

  &__items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  &__item {
    @extend %noselect;

    background: url('../img/custom_btn_bg.png') repeat-x;
    background-color: #f9f9f9;
    border: 1px solid $border;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    padding: 9px;
    margin: 11px 0;

    @include desktop() {
      font-size: 14px;
    }

    &.selected {
      border: 1px solid transparent;
      background: #666;
      color: white;
    }

    &.disabled {
      background: none;
      background-color: rgb(235, 235, 228);
      color: rgb(84, 84, 84);
    }
  }

  &__wrap {
    clear: right;
    height: 0;
    padding-right: 5px;
    overflow-y: hidden;
    overflow-x: visible;
    transition: height 0.2s ease-in;
  }

  &__content {
    transition: opacity 0.3s ease;
    opacity: 0;

    .open & {
      opacity: 1;
    }
  }

  &__title {
    cursor: pointer;

    .inline-svg {
      width: 16px;
    }
  }

  &__color {
    margin-top: 2px;
    margin-right: 5px;
    float: left;
    width: 15px;
    height: 15px;
  }

  &__close {
    transform: rotate(45deg);
    color: #ccc;
    display: inline-block;
    font-size: 32px;
    position: absolute;
    right: 5px;
    top: 0;
  }

  .open {
    -webkit-perspective: 10000px;
    perspective: 10000px;
    background-color: white;
    border: 1px solid $border;

    &:not(:first-child) {
      border-top: 1px solid transparent;
    }
  }
}
