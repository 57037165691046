@import '@nextiva/next-ui/styles/colors';


.reportTable {
  display: flex;
  flex-direction: column;
  height: 70vh;

  :global {
    .nx-textfield__input {
      border: 0;
    }

    .nx-table__row--clickable {
      cursor: default;
    }
  }
}

.volumeIcon {
  font-size: 16px;
  color: color(graphite);
  cursor: pointer;
  transition: color .3s ease-in-out;

  &:hover {
    color: #000;
  }
}

.directionIcon {
  width: 20px;
  height: 20px;

  :global(path) {
    fill: color(gray);
  }
}

.list {
  display: flex;
  flex-direction: column;
  flex: auto;

  & > div:last-child {
    display: flex;
    flex: auto;
  }
}

.table {
  width: 100%;
}