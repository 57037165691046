.wallboard {
  width: 100%;

  .content-box-content {
    padding: 22px 5px;
  }
}

.wallboard-original {
  width: 23.5%;
  height: 150px;
  float: left;
  margin-left: 15px;
  margin-bottom: 10px;
  font-family: lato;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;

  &__title {
    font-weight: 200;
    font-size: 26px;
    display: inline-block;
    border-bottom: 1px solid #e7e7e7;
    width: 100%;
  }

  &__value {
    font-size: 42px;
    padding-top: 15px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
  }

  &__unit {
    padding-left: 5px;
    font-size: 22px;
    opacity: 0.6;
    display: inline-block;
    position: relative;
    vertical-align: baseline;
    top: -18px;
    transition: all 0.3s ease-in-out;
  }
}

.wallboard-container {
  display: flex;
  flex-wrap: wrap;
}

.wallboard-iconic {
  width: 24%;
  height: 150px;
  margin: 0.5%;
  padding: 20px 0 20px 15px;
  color: white;
  font-family: lato;
  box-sizing: border-box;

  @include desktop() {
    width: 49%;
  }

  &__icon {
    height: 30px;
    width: 30px;

    path {
      fill: white !important;
    }
  }

  &--title-wrapper {
    display: flex;
  }

  &__title {
    font-weight: 400;
    font-size: 23px;
    position: relative;
    padding-left: 10px;
  }

  &__value {
    margin-top: 15px;
    margin-left: 25px;
    font-size: 42px;
    display: inline-block;
  }

  &__unit {
    top: -20px;
    left: 10px;
    position: relative;
    display: inline-block;
    font-size: 26px;
    opacity: 0.6;
  }
}
