@font-face {
  font-family: 'lato';
  src: url('../font/lato-light-webfont.woff') format('woff'), url('../font/lato-light-webfont.svg#latolight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-regular-webfont.woff') format('woff'), url('../font/lato-regular-webfont.svg#latolight') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-italic-webfont.woff') format('woff'), url('../font/lato-italic-webfont.svg#latoitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-lightitalic-webfont.woff') format('woff'), url('../font/lato-lightitalic-webfont.svg#latolight_italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-hairlineitalic-webfont.woff') format('woff'), url('../font/lato-hairlineitalic-webfont.svg#latohairline_italic') format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-hairline-webfont.woff') format('woff'), url('../font/lato-hairline-webfont.svg#latohairline') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-black-webfont.woff') format('woff'), url('../font/lato-black-webfont.svg#latoblack') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'latobold_italic';
  src: url('../font/lato-bolditalic-webfont.woff') format('woff'), url('../font/lato-bolditalic-webfont.svg#latobold_italic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-bold-webfont.woff') format('woff'), url('../font/lato-bold-webfont.svg#latobold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'lato';
  src: url('../font/lato-blackitalic-webfont.woff') format('woff'), url('../font/lato-blackitalic-webfont.svg#latoblack_italic') format('svg');
  font-weight: 800;
  font-style: italic;
}
