.date-range {
  color: #444;
  font-size: 19px;

  .nx-popover-target:first-child {
    margin-left: 0;
  }

  @extend %noselect;

  input[type="checkbox"] {
    position: relative;
    top: 4px;
    margin-right: 10px;
  }

  .real-time {
    margin-right: 10px;
  }

  .dates {
    font-size: 16px;
  }

  .divider {
    padding: 4px;
  }

  .time {
    margin-left: 8px;
  }

  .real-time-refresh {
    width: 17px;
    height: 17px;
    position: relative;
    vertical-align: top;
    top: 3px;
    margin-right: 10px;
    cursor: pointer;

    path {
      fill: #999;
    }
  }

  .real-time-refresh.blue {
    path {
      fill: $blue;
    }
  }

  & > span {
    display: inline-block;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.real-time-refresh.blue {
  -webkit-animation: spin 0.4s;
  -webkit-animation-timing-function: linear;
}
