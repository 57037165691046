@charset "UTF-8";
.description {
  margin: 10px 0 0;
  padding-right: 10px;
  text-align: right;
  color: #999;
  font: 400 italic 14px lato; }

.invalid {
  margin: 5px 0 0;
  padding-left: 10px;
  font: 400 16px lato;
  color: #f26767; }

.invalidList {
  margin: 0;
  padding-top: 5px;
  padding-left: 10px; }

.item {
  padding: 5px 10px; }
  .item:before {
    content: '—';
    padding-right: 8px; }
