.call-recording-player {
  position: relative;

  .canvas-container {
    min-height: 120px;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .clip-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;

    .icon-custom-triangle-down {
      font-size: 12px;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: -10px;
      color: white;
      text-shadow: #333 0 1px 1px;
    }

    .clipTool {
      position: relative;
      width: 20px;
      height: 20px;
    }

    .left-fill {
      opacity: 0.3;
      height: 100px;
      float: left;
      background-color: #082461;
    }

    .right-fill {
      position: absolute;
      opacity: 0.3;
      height: 100px;
      float: left;
      background-color: #082461;
    }
  }

  .controls {
    z-index: 200;
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #323334;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .icon-custom-circle-play,
    .icon-custom-circle-pause,
    .icon-custom-volume-high {
      cursor: pointer;
      color: white;
    }

    .icon-custom-volume-high {
      font-size: 14px;
    }

    .current-time {
      position: absolute;
      top: -15px;
      left: 10%;
      padding-left: 5px;
      color: #333;
      font-size: 11px;
    }

    .volume-toggle {
      z-index: 200;
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
      height: 12px;
      background-color: #76d547;
    }

    .play {
      width: 10px;
      height: 10px;
    }

    .progress-container {
      cursor: pointer;
      position: relative;
      background-color: #000001;
      width: 80%;
      height: 12px;
      border-radius: 10px;
    }

    .volume-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      right: 0;
      top: -90px;
      height: 90px;
      width: 35px;
      background-color: #434445;
    }

    .volume-progress-container {
      cursor: pointer;
      position: relative;
      height: 80%;
      border-radius: 10px;
      background-color: #000001;
      width: 12px;
    }

    .volume-progress {
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 10px;
      width: 12px;
      background-color: #76d547;
    }

    .handle {
      cursor: pointer;
      position: relative;
      top: -2px;
      left: -2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: white;
    }
  }
}
