.projections {
  border-top: 1px solid $border;
  padding-top: 15px;
  padding-bottom: 50px;

  h3 {
    font-size: 18px;
    color: #666;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .buttons {
    margin-bottom: 20px;
  }

  .projection-btn {
    border: 1px solid $border;
    background-color: #f8f8f8;
    color: #666;
    font-weight: 300;
    border-radius: 2px;
    padding: 10px 0;
    margin-right: 10px;
    font-size: 17px;
    width: 200px;
  }

  .projection-btn.active {
    background-color: #666;
    color: white;
    border: none;
  }

  .info-icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    position: relative;
    top: 3px;
    padding-right: 15px;

    path {
      fill: white !important;
    }
  }

  select {
    font-size: 16px;
    width: 200px;
    float: right;
    margin-bottom: 25px;
  }
}
