@import 'styles/mixins';
@import '~@nextiva/next-ui/styles/colors';

.ReactFlipCard {
  margin: 0 10px;
  width: 220px;
  height: 220px;
  color: #fff;
  cursor: pointer;

  @include tablet() {
    width: 220px;
    height: 220px;
  }

  h2 {
    padding: 0;
    font-weight: 700;
    font-size: 18px;
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      font-weight: bold;
    }
  }

  .inline-svg {
    height: 125px;
    width: 125px;
  }
}

.ReactFlipCard__Flipper {
  height: 100%;
}

.ReactFlipCard__Front,
.ReactFlipCard__Back {
  height: 100%;
  width: 100%;
  text-align: center;
}

.ReactFlipCard__Front {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ReactFlipCard__Back {
  h2 {
    margin: 27px 0 0;
  }
}

.landing-content {
  position: absolute;
  bottom: 0;
  height: 166px;
  background-image: url(../img/landing_overlay.png);

  p {
    margin: 0;
    padding: 29px 29px 0;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
  }
}

#landing-report,
#landing-overview {
  .ReactFlipCard__Front,
  .ReactFlipCard__Back {
    background-color: #f8b317;
  }
}

#landing-monitor,
#landing-cases {
  .ReactFlipCard__Front,
  .ReactFlipCard__Back {
    background-color: #22c961;
  }
}

#landing-analyze,
#landing-channels {
  .ReactFlipCard__Front,
  .ReactFlipCard__Back {
    background-color: #00a4ef;
  }
}

#landing-customize,
#landing-customers {
  .ReactFlipCard__Front,
  .ReactFlipCard__Back {
    background-color: #f87617;
  }
}

#landing-save,
#landing-tools {
  .ReactFlipCard__Front,
  .ReactFlipCard__Back {
    background-color: #0f58a5;
  }
}

#landing-set,
#landing-settings {
  .ReactFlipCard__Front,
  .ReactFlipCard__Back {
    background-color: #3a7691;
  }
}
#landing-quality,
#landing-quality {
  .ReactFlipCard__Front,
  .ReactFlipCard__Back {
    background-color: #9E68D1;
  }
}
