.tool-tip-container {
  width: 317px !important;
  min-height: 110px !important;
  padding: 27px 27px 8px 122px !important;
  color: #333 !important;
  border: none !important;
  background-color: transparent !important;
  background-image: url(../img/tool_tip_bg.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  line-height: 24px;

  h4 {
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
}

.extended-text-tooltip,
.data-table-tooltip-container {
  background-color: #fff;
  border: 2px solid $border;
  line-height: 20px;
  word-wrap: break-word;
  width: 300px;

  &::after {
    display: none;
  }
}

.ico_tooltip {
  margin: 0 0 0 3px;
  cursor: pointer;
  vertical-align: middle;
}
