.resize-icon {
  bottom: 5px !important;
  cursor: ns-resize;
  height: 15px;
  text-align: center;
}

.chart-no-data {
  position: relative;
  min-height: 200px;
}

.amcharts-main-div {
  .amcharts-legend-div {
    left: 42px;
    max-width: 95%;
  }
}

.pie-chart .amcharts-legend-div {
  left: 0;
}

.sample-overlay {
  position: absolute;
  font-size: 160px;
  font-weight: 800;
  z-index: 10;
  color: #333;
  opacity: 0.4;
  top: 100px;

  @extend %noselect;

  transform: rotate(25deg);
  left: 125px;

  @include desktop() {
    font-size: 120px;
  }

  @include tablet() {
    left: 25px;
    font-size: 96px;
  }
}

.pie-chart {
  width: 100%;
  height: 500px;
  font-size: 11px;
}

.barchart {
  width: 100%;
  height: 500px;
  font-size: 11px;
  overflow: visible !important;
}

.scatterchart {
  width: 100%;
  height: 500px;
  font-size: 11px;
}

.linechart {
  width: 100%;
  height: 500px;
  overflow: visible !important;
}

.heat {
  width: 100%;
  height: 500px;
}

.multiaxischart {
  width: 100%;
  height: 500px;
  font-size: 11px;
}

.export-main {
  font-size: 12px !important;
}
