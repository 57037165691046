@import 'styles/mixins';

.report-controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  border-bottom: 1px solid $border;

  .label-and-date-picker {
    display: flex;
    color:#666666;
  }

  @include tablet() {
    display: block !important;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  .react-select-box-container {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }

  .dates {
    font-size: 15px;
  }

  .date-range {
    display: inline-flex;
    margin-left: 23.97px;

    input[type="checkbox"] {
      margin-right: 5px;
    }
  }

  .real-time {
    margin-right: 0;
    margin-left: 10px;
    width: 66px !important;
  }

  .button-popover-container {
    display: inline-block;
    margin-left: 5px;
  }

  .button-popover-icon {
    font-size: 14px;
    margin-left: 5px;
  }

  .call-filters {
    margin-left: auto;
  }

  .connected-call-filters {
    display: flex;
    margin-left: auto;
    padding: 0;

    li {
      display: inline-block;
      margin-left: 30px;
      text-align: center;
    }

    li div:first-child {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 2px;
    }

    li div:nth-child(2) {
      font-size: 14px;
    }

    div {
      color: #666666;
    }

    .divider {
      position: relative;
      width: 2px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 44px;
        width: 1px;
        background-color: #BFC0C6;
        transform: translate(-50%, -50%);
      }
    }

    @include tablet {
      li {
        margin-left: 35px;
      }
    }
  }
}
