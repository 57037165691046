@import 'styles/variables';
@import 'styles/colors';
@import 'styles/mixins';

.header {
  display: flex;
  justify-content: center;
  background-color: $color-dark-gray-blue;
  height: $header-height;

  @include desktop() {
    height: 50px;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;

  @include desktop() {
    padding: 0 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  padding: 10px 50px 0;
  box-sizing: border-box;

  @include desktop() {
    padding: 0 20px;
    min-height: calc(100vh - 50px - #{$footer-height});
  }
}

.service {
  margin-top: 5%;
  font-size: 18px;

  h1 {
    font-weight: 600;
    font-size: 32px;
  }
}
