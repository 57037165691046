@import 'styles/colors';
@import 'styles/colors';
@import 'styles/mixins';

.navigation {
  display: flex;
  align-items: center;
  height: 100%;
}

.list {
  margin: 0;
  padding: 0 50px;
  display: flex;
  flex: 2 1 auto;
  flex-flow: row nowrap;
  height: 100%;
  list-style: none;

  @include tablet() {
    padding: 0 20px;
  }
}

.item {
  height: 100%;

  .dropdown__content {
    margin-top: 54px;
  }

  @include ipad-portrait() {
    margin-right: 12px;
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  height: 100%;
  padding: 0 15px;
  color: $side-nav-default-color;
  text-decoration: none;
  transition: color 0.3s ease;

  @include tablet() {
    padding: 0 10px;
    font-size: 14px;
  }

  &:hover {
    color: #fff;
  }
}

.active {
  color: #fff;
  background: $side-nav-active-bg;

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    transform: translateX(-50%);
  }
}
