.table-panel {
  position: relative;
  border-top: 1px solid $border;
  padding-top: 15px;
  box-sizing: border-box;

  h3 {
    font-size: 18px;
    color: #666;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .buttons {
    margin-bottom: 20px;
  }

  .report-call-log {
    position: relative;
    min-height: 350px;
    margin-bottom: 10px;
    
    & > div {
      height: 90vh;

      &.data-empty,
      &.error-placeholder {
        height: 350px;
      }
    }
  }
}

.table-panel-btn {
  border: 1px solid $border;
  background-color: #f8f8f8;
  color: #666;
  font-weight: 300;
  border-radius: 2px;
  padding: 10px 0;
  margin-right: 10px;
  font-size: 17px;
  width: 200px;
}

.table-panel-btn.active {
  background-color: #666;
  color: white;
  border: none;
}
