

.exportData {
  padding: 20px 16px;
  width: 300px;
  box-sizing: border-box;
}

.title {
  margin: 0;
}

.email {
  margin-top: 15px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancel {
  margin-right: 10px;
}