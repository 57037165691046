$blue: #3d85d1;
$orange: #ffa82d;
$green: #8ec548;
$red: #fd2926;
$darkRed: #d34242;
$yellow: #ecec28;
$border: #e7e7e7;
$input-border: #d3d3d3;
$hover-bg: #ebf5ff;
$hover-color: #3c7dba;
$good:#5FCFC1;
$moderate:#F19D3C;
$poor:#D7563C;

.blue {
  color: $blue;
}

.orange {
  color: $orange;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

// colors from @nextiva/next-ui
$charcoal: #333;
$cool-gray-1: #f2f2f2;
$brand-yellow: #f8b400;
$color-dark-gray-blue: #314358;
$side-nav-active-bg: #26384e;
$side-nav-default-color: #98a1ac;

$table_content:#666666;
