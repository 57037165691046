.site-nav {
  display: flex;
  justify-content: center;
  background-color: #091f36;
  color: white;
  min-height: 69px;
  margin-bottom: 1em;

  @include ipad() {
    text-align: left;
  }

  &__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    width: 1205px;

    @include ipad-portrait() {
      width: 768px;
    }

    @include ipad-landscape() {
      width: 1024px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__items {
    display: flex;
    flex: 2 1 auto;
    flex-flow: row nowrap;
    height: 100%;
    list-style: none;
    margin-right: 50px;

    @include ipad-portrait() {
      margin-right: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    height: 100%;
    color: white;
    margin-right: 26px;

    .dropdown__content {
      margin-top: 54px;
    }

    @include ipad-portrait() {
      margin-right: 12px;
    }
  }

  &__link {
    text-decoration: none;
    color: white;

    &.active::before {
      content: " ";
      position: relative;
      top: 17px;
      left: 48%;
      margin: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #f7f7f7;
    }
  }
}

.user-settings {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  height: 100%;

  @include ipad() {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
  }

  &--platform {
    width: auto;
    height: 100%;

    .dropdown__content {
      margin-top: 54px;
    }
  }

  &__name {
    flex: 2 1 auto;
    display: block;
    text-overflow: ellipsis;
    max-width: 200px;
    width: auto;
    overflow: hidden;
    margin-left: 8px;
    white-space: nowrap;
  }
}

main .nav {
  clear: both;

  .page-controls {
    padding-top: 5px;
    float: right;
    margin-right: 10px;

    li {
      cursor: pointer;
      list-style-type: none;
      margin-right: 20px;
      display: inline-block;
    }

    li:last-child {
      margin-right: 0;
    }

    @include ipad() {
      padding-top: 0;
      margin-top: -10px;
    }
  }

  .page-sections {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #e1e1e1;

    li {
      list-style-type: none;
      margin-right: 0;
      display: inline-block;
      height: 61px;

      @include ipad() {
        height: 43px;
      }
    }

    a {
      margin: 1px;
      margin-bottom: 0;
      font-size: 22px;
      display: block;
      padding: 15px 24px 19px;
      color: $blue;
      font-family: "lato";
      font-weight: 300;

      @include ipad() {
        font-size: 16px;
        padding: 10px 14px 14px;
      }
    }

    li .active {
      color: #555;
      font-weight: 300;
      margin: 0;
      background-color: white;
      border: 1px solid #e1e1e1;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
    }

    a {
      text-decoration: none;
    }
  }
}
