.analytics-panel {
  -webkit-perspective: 10000px;
  perspective: 10000px;

  .base-panel-item {
    position: relative;
    color: #777;
    float: left;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    font-family: lato;
    cursor: pointer;
    padding: 20px 0;
  }

  .base-panel-item:not(:last-child)::after {
    content: ' ';
    position: absolute;
    top: 10%;
    right: -1px;
    width: 1px;
    background-color: $border;
  }

  .panel-box {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    
  }

  .panel-item.panel-selected {
    @extend %noselect;

    background-color: #3d85d1;
    border-radius: 3px;
    color: white;

    .panel-percent {
      color:  #5FCFC1;
      font-weight: bold;
      font-size: 18px;

    }

    .arrow path {
      fill: white !important;
    }
  }

  .panel-title {
    height: 25px;
    color: #666666;
    font-size: 18px;
    font-weight: 400;
  }

  .panel-percent {
    padding-top: 6px;
    font-size: 24px;
    font-weight: 400;
    color: #666;
  }

  .panel-item.panel-selected::after {
    display: none;
  }

  .number-type {
    font-size: 11px;
  }

  .panel-number {
    margin-top: 3px;
    font-size: 18px;
    color: #666666;
    font-weight: 400;
  }

  .arrow {
    vertical-align: top;
    display: inline-block;
    width: 20px;
    height: 33px;
    margin-right: 4px;

    &.down {
      transform: rotateX(180deg);
      transform-origin: -10% 50%;
    }

    & path {
      fill: #666 !important;
    }
  }
}

.analytics-panel.trend {
  .panel-item {
    @extend .base-panel-item;

    width: 16%;

    @include desktop() {
      width: 33.33%;
    }
  }

  .panel-item:not(:last-child)::after {
    @extend .base-panel-item:not(:last-child)::after;

    height: 80%;
  }

  .hideAfter {
    &::after {
      display: none;
    }
  }

  

  @include ipad-portrait() {
    .panel-item {
      width: 33.33%;
    }

    .panel-item:nth-child(-n + 3) {
      border-bottom: 1px solid $border;
    }

    .panel-item:nth-child(n + 3)::after {
      top: 0;
    }

    .panel-item:nth-child(3)::after {
      display: none;
    }

    .panel-item:not(:last-child)::after {
      height: 90%;
    }
  }
}

.analytics-panel.comparison {
  .panel-comparision-item {
    margin: 0 auto;
    width: 85%;
  }

  .panel-box {
    // flex-flow: row wrap;
    display:flex;
    flex-direction: row;
  }


  .panel-item {
    @extend .base-panel-item;

    // width: 33.33%;
    width:25%;
    
  }
  
  .panel-item:nth-child(2) span {
    color:  #666666;
    font-weight:400;
  }

  //specific color to percentages
  .panel-number span {
    color: #5FCFC1;
    padding: 10px;
    font-weight: bold;
    font-size: 18px;
  }
  

  .panel-item:not(:last-child)::after {
    @extend .base-panel-item:not(:last-child)::after;

    height: 75%;
  }

  .panel-item {
    border-bottom: 1px solid $border;
  }

  .panel-item:nth-child(n + 4)::after {
    top: 0;
    
   
  }

  .hide-border {
    border-bottom: 0;
  }

  .panel-item:nth-child(4n)::after {
    display: none;
  }
}

.analytics-panel.period {
  .panel-comparision-item {
    margin: 0 auto;
    width: 85%;
  }

  .panel-box {
    flex-flow: row wrap;
  }

  .panel-item {
    @extend .base-panel-item;

    width: 33.33%;
  }

  .panel-item:not(:last-child)::after {
    @extend .base-panel-item:not(:last-child)::after;
    

    height: 90%;
  }

  .panel-item {
    border-bottom: 1px solid $border;
    
  }

  .panel-item:nth-child(n + 3)::after {
    top: 0;
    height: 100%;
  }

  .hide-border {
    border-bottom: 0;
  }

  .panel-item:nth-child(3n)::after {
    display: none;
  }
}
