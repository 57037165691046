
@import 'styles/mixins';
@import '~@nextiva/next-ui/styles/colors';

.link {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid color(gray);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.6s ease-in-out;

  @include desktop() {
    width: 15px;
    height: 15px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid color(gray);
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;

    @include desktop() {
      border-top-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 6px;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    border-color: color(nextiva-blue);

    &::before {
      border-left-color: color(nextiva-blue);
    }
  }
}
