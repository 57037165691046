@import 'styles/colors';
@import 'styles/mixins';

.user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  height: 100%;
  color: #fff;

  @include ipad() {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
  }

  &__dropdown {
    margin-right: 10px;

    .btn-transparent {
      display: flex;
      padding: 0;
    }

    .svg-none {
      .cls-2 {
        fill: $side-nav-default-color;
        transition: fill 0.3s ease;
      }

      &:hover .cls-2 {
        fill: #fff;
      }
    }

    &.active {
      .svg-none {
        opacity: 1;
      }
    }
  }
}
