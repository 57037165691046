.react-select-box-container {
  height: 38px;
  vertical-align: middle;
  position: relative;
  width: 170px;
  display: block;
  background-color: #fff;
  border: 1px solid $input-border;
  text-align: left;
  outline: none !important;

  div,
  button,
  a {
    outline: none !important;
  }
}

.multiselect-icon-dropdown {
  height: 30px;
  position: relative;
  top: 2px;
  display: block;
  outline: none !important;

  .inline-svg {
    height: 17px;
    width: 17px;
    display: inline-block;

    path {
      fill: #ccc !important;
    }
  }

  .triangle {
    vertical-align: top;
    position: relative;
    bottom: 3px;
    margin-left: 5px;
    height: 13px !important;
    width: 13px !important;

    path {
      fill: #ddd !important;
    }
  }

  div,
  button,
  a {
    outline: none !important;
  }
}

.react-select-box-container {
  .react-select-box {
    padding: 11px 0;
    display: inline-block;
    cursor: pointer;
    border: none;
    width: 100%;
    text-align: left;
    background-color: transparent;
  }

  .react-select-box::after {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 8px;
    border-top: 8px solid #ccc;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}

.react-select-box-label,
.react-select-box-option {
  line-height: 16px;
  font-size: 16px;
  font-weight: 300;
  color: #444;
}

.react-select-box-label {
  padding: 0 20px 3px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
  font-size: 16px;
  font-family: "lato";
  font-weight: 300;
}

.react-select-box-click-outside-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.react-select-box-searchable {
  .react-select-box-search {
    margin-top: -5px;
    margin-left: -1px;
    width: 110%;
    background-color: white;
    z-index: 1000;
    font-size: 16px;
    position: relative;
    box-sizing: border-box;
  }

  .react-select-box-select {
    margin-top: -1px;
    margin-left: -1px;
    z-index: 1002;
    height: 2.2em;
    width: 110%;
    color: #333;
    background-color: #efefef;
    border: 1px solid $input-border;
    position: relative;
    box-sizing: border-box;
  }

  .react-select-box-select:hover {
    border: 1px solid #ccc !important;
    background-color: #e7e7e7 !important;
  }

  .react-select-box-options {
    margin-top: 78px;
    z-index: 1001;
    box-shadow: none;
    margin-left: -1px;
    border: 1px solid $input-border;
    border-top: 1px solid #eee;
    width: 110%;
    box-sizing: border-box;
  }
}

.react-select-box-clear {
  display: none;
  position: absolute;
  top: 15px;
  right: 0;
  width: 35px;
  height: 20px;
  background-color: #fff;
  text-indent: -9999em;
  z-index: 3;
  border: none;
}

.react-select-box-hidden {
  display: none;
}

.react-select-box-options {
  margin: 3px 0 0;
  position: absolute;
  width: 200px;
  top: 100%;
  left: 0;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  max-height: 600px;
  overflow: auto;
  overflow-x: hidden;
}

.multiselect-align-left .react-select-box-options {
  top: 80%;
  right: 0;
  left: initial;
}

.react-select-box-options-list {
  list-style: none outside;
  margin: 0;
  padding: 0;
}

.react-select-box-option {
  padding: 10px 5px;
  margin: 0;
  cursor: pointer;
  display: block;
  line-height: 1.2;
  text-decoration: none;
}

.react-select-box-option:hover {
  color: $hover-color;
  background-color: $hover-bg;
}

.react-select-box-multi .react-select-box-option {
  padding-left: 40px;
  position: relative;
}

.react-select-box-multi .react-select-box-option::before {
  content: ' ';
  position: absolute;
  line-height: 1;
  text-align: center;
  left: 10px;
  top: 6px;
  height: 21px;
  width: 21px;
  margin-right: 10px;
  border: 1px solid #e5e5e5;
  background: #fff;
  vertical-align: middle;
}

.react-select-box-option-selected.react-select-box-option::before {
  border: 1px solid #8cc64b;
  background: url(../img/checkmark.png) no-repeat;
}

.react-select-box-close {
  cursor: pointer;
  display: inline-block;
  padding: 9px 20px;
  text-align: center;
  background-color: $green;
  color: white;
  border: 1px solid transparent;
  width: 100%;
}

.react-select-box-close:hover,
.react-select-box-close:focus {
  text-decoration: none;
}

.react-select-box-native {
  position: absolute;
  left: -99999em;
}
