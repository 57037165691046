@import '~@nextiva/next-ui/styles/colors';

.user-management-container {
  padding: 5px 0;

  .avatar {
    height: 37px;
    width: 37px;
    border: 1px solid #e1e1e1;
    border-radius: 50%;
    cursor: pointer;
  }
}

.manage-user-info-container {
  .manage-user-row {
    display: flex;
    flex-direction: row;
    margin: 24px 0;
  }

  .manage-user-item {
    margin-right: 24px;
  }

  .manage-user-avatar {
    cursor: pointer;
  }

  h2 {
    font-weight: 100;
    font-size: 1.25em;
    line-height: 60px;
  }

  .avatar-lg {
    width: 95px;
    height: 95px;
    border: 1px solid #e1e1e1;
    border-radius: 50%;
  }

  .edit-avatar {
    font-weight: 300;
    font-size: 15px;
    padding: 4px 0 0;
    text-align: center;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
}

.user-management-search-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;

  label {
    margin-right: 10px;
  }
}

.user_management_popover {
  .Popover-body {
    padding: 0.5rem 1rem;
    background: white;
    color: black;
    border-radius: 0.3rem;
    border: 2px solid darken($border, 15%);
    width: 550px;
    overflow: hidden;
  }

  .avatar-popup {
    display: flex;
  }

  .avatar-crop {
    width: 260px;
  }

  .avatar-controls {
    margin-left: 15px;
    overflow: hidden;

    h4,
    h5 {
      margin: 15px 0 0;
    }

    input {
      margin-top: 10px;
      display: block;
    }
  }

  .img-preview {
    margin-top: 15px;
    width: 64px;
    height: 64px;
    overflow: hidden;
    padding: 2px;
    border: 1px solid color(mercury);
    border-radius: 3px;
  }
}

.cropper-container {
  font-size: 0;
  line-height: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: ltr !important;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.cropper-container img {
  display: block;
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  height: 100%;
  min-height: 0 !important;
  max-height: none !important;
  image-orientation: 0deg !important;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cropper-wrap-box {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.cropper-modal {
  opacity: 0.5;
  background-color: #000;
  filter: alpha(opacity=50);
}

.cropper-view-box {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
}

.cropper-dashed {
  position: absolute;
  display: block;
  opacity: 0.5;
  border: 0 dashed #eee;
  filter: alpha(opacity=50);
}

.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}

.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.cropper-center::before,
.cropper-center::after {
  position: absolute;
  display: block;
  content: ' ';
  background-color: #eee;
}

.cropper-center::before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}

.cropper-center::after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}

.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}

.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}

.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}

.cropper-point {
  width: 5px;
  height: 5px;
  opacity: 0.75;
  background-color: #39f;
  filter: alpha(opacity=75);
}

.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}

.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}

.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}

.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}

.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}

.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}

.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}

.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
  filter: alpha(opacity=100);
}

.cropper-point.point-se::before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: ' ';
  opacity: 0;
  background-color: #39f;
  filter: alpha(opacity=0);
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
}

.cropper-invisible {
  opacity: 0;
  filter: alpha(opacity=0);
}

.cropper-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
