.datetime,
.hours-minutes {
  position: relative;
  color: #666;
  display: inline-block;

  .date-button {
    @extend %noselect;

    text-align: left;
    white-space: nowrap;
    vertical-align: top;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    font-size: 15px;
    position: relative;
    background-color: #fff;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #eee;
    }

    padding: 11px 8px 11px 20px;
    padding-left: 8px;
    padding-right: 20px;

    .css-arrow-down {
      position: absolute;
      top: 15px;
      right: 8px;
    }
  }

  .datepicker-input {
    position: absolute;
    bottom: 40px;
    width: 1px;
    height: 1px;

    .react-datepicker-wrapper {
      visibility: hidden;
    }
  }

  > div {
    display: inline-block;
  }

  .arrow {
    position: relative;
    left: 194px;
    top: -23px;
  }

  .label {
    margin-bottom: 20px;
    color: #555;
    font-size: 0.95em;
  }

  .hours,
  .minutes {
    margin: 0 !important;
    width: 20px;
    outline: none;
  }

  .colon {
    margin: 0 7px;
    font-size: 0.9em;
    color: #c0c0c0;
    font-weight: 300;
  }

  .at {
    margin: 0 10px;
    font-size: 0.9em;
    color: #c0c0c0;
    font-weight: 300;
  }

  .date {
    width: 120px;
  }

  .meridiem {
    font-size: 1em;
    margin-left: 6px;
    border: none;
    width: 70px;
  }

  input {
    border: 1px solid $input-border;
    font-size: 16px;
    padding: 10px;
  }
}

.datetime-dropdown {
  position: absolute;

  .options {
    opacity: 0.99;
    z-index: 101;
    position: absolute;
    width: 200px;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
    }
  }

  .option {
    cursor: pointer;
    font-size: 15px;
    padding: 10px;
  }

  .option:last-child {
    border-bottom: none;
  }

  .option:hover {
    background-color: $hover-bg;
  }
}

.real-time,
.round {
  color: #888;
  font-size: 0.95em;
  display: inline-block;
  width: 125px;

  input {
    position: relative;
    top: 4px;
    margin-right: 10px;
  }
}

.round {
  width: 120px !important;
  font-size: 17px;
  vertical-align: baseline;
}
