@import 'styles/mixins';

.analytics-controls {
  display: flex;
  align-items: center;
  clear: both;
  margin-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 0 20px;

  &.analytics-comparison {
    @include tablet() {
      flex-wrap: wrap;
    }

    .additional-controls {
      @include tablet() {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  .additional-controls {
    display: flex;
    align-items: center;

    @include tablet() {
      width: 100%;
    }
  }

  > .chart-dropdown {
    padding: 8px 0;
  }

  .react-select-box-container {
    width: 180px;
    margin-right: 35px;
  }

  .comparison-dropdowns select,
  > select {
    height: 40px !important;
    margin-right: 35px;
    width: 180px;
    font-size: 16px;

    @include desktop() {
      margin-right: 20px;
    }
  }

  .comparison-dropdowns select {
    @include desktop() {
      width: 160px;
      margin-right: 10px;
    }
  }

  .chart-buckets {
    margin-left: 20px;
  }

  .analytics-controls-gear .svg-overlay {
    left: -25px !important;
  }

  .button-popover-container {
    margin-left: 5px;
  }

  .button-popover-icon {
    font-size: 14px;
    margin-left: 5px;
  }
}

.period-select {
  select {
    width: 180px;
  }
}
