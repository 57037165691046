/* Reset leaderboard styles */
.theme-reset {
  position: relative;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .collapseable {
    margin-bottom: 24px;

    .modal {
      * {
        color: #666 !important;
      }

      .active,
      .update {
        color: #fff !important;
      }

      option {
        background-color: #fff !important;
      }
    }

    select {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
    }

    .react-select-box-container {
      background-color: transparent !important;
    }
  }

  .react-select-box-option {
    &::before {
      width: 19px !important;
      height: 19px !important;
      top: 9px !important;
    }
  }

  .popout & {
    .content-box-header {
      padding-top: 0;
    }
  }

  .content-box-header {
    margin: 22px 22px 0 0;
    padding: 0;
    border: none;

    .labels {
      padding: 0 0 0 22px;
      width: 50%;
      background-repeat: no-repeat;
      background-position: center left;
    }
  }

  .leaderboard {
    padding: 22px;
    margin-left: 0;

    .leaderboard-row {
      margin-bottom: 3px;
      height: 109px;

      &:first-child {
        .place-container,
        .place-container-no-avatar {
          background-position: top left;
        }

        .leaderboard-row-content {
          background-position: top right;
        }
      }

      .place-container,
      .place-container-no-avatar {
        padding: 4px 7px 0;
        height: 105px;
        background-position: bottom left;
      }

      .leaderboard-row-content {
        margin-left: 0;
        height: 109px;
        background-position: bottom right;
        background-repeat: no-repeat;

        .leaderboard-row-name {
          padding: 34px 0 20px;
        }

        .leaderboard-row-stats {
          margin-top: 12px;
        }

        .leaderboard-row-border {
          display: none;
        }
      }
    }
  }
}

.asc {
  .leaderboard-row {
    &:first-child {
      .place-container,
      .place-container-no-avatar {
        background-position: bottom left !important;
      }

      .leaderboard-row-content {
        background-position: bottom right !important;
      }
    }

    &:last-child {
      .place-container,
      .place-container-no-avatar {
        background-position: top left !important;

        .circle {
          text-shadow: none;
        }
      }

      .leaderboard-row-content {
        background-position: top right !important;
      }
    }
  }
}

/* Theme City */
.theme_city {
  background-image: url(~modules/monitoring/assets/city/city.jpg) !important;
}

.theme_city,
.theme_tech,
.theme_sports,
.theme_sports_2,
.theme_sports_3 {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .content-box-header {
    position: relative;
  }
}

/* Theme: Retro */
.theme_retro {
  .content-box-header {
    margin: 0 22px 22px;
    padding: 22px 0 16px;
    border-bottom: 1px solid #e7e7e7;

    .popout {
      .content-box-header {
        padding-bottom: 0;
      }
    }

    .labels {
      margin: 0;
    }
  }

  .leaderboard {
    .leaderboard-row {
      height: 118px !important;

      .circle {
        margin: 10px 0 0 13px;
        border: 14px solid #fff;
        width: 55px;
        height: 55px;
        background-color: #b7b7b7;
        line-height: 1.9;
        font-size: 30px;

        sup {
          font-size: 16px;
        }
      }

      .leaderboard-row-content {
        .leaderboard-row-name {
          font-weight: 300;
          color: #b7b7b7;
          font-size: 30px;
          padding: 42px 0 24px;
        }

        .leaderboard-row-border {
          display: block;
          background-image: linear-gradient(to right, white 2%, #b7b7b7);
        }

        .leaderboard-row-stats {
          .counter-section > span {
            color: #b7b7b7;
          }
        }

        .count {
          span {
            background-color: #b7b7b7;

            &::before {
              border-bottom: 1px solid #b7b7b7;
            }
          }
        }
      }

      &:first-child {
        .circle {
          margin: 0 !important;
          border: none;
          width: 102px;
          height: 102px;
          background-color: #2a99d3;
          font-size: 63px;
          line-height: 1.6;

          sup {
            top: -27px;
            font-size: 30px;
          }
        }

        .leaderboard-row-content {
          .leaderboard-row-name {
            font-weight: 700;
            color: #000;
            font-size: 44px;
            padding: 32px 0 17px;
          }

          .leaderboard-row-border {
            background-image: linear-gradient(to right, white 2%, #2a99d3);
          }

          .leaderboard-row-stats {
            .counter-section > span {
              color: #333;
            }
          }
        }

        .count {
          span {
            background-color: #333;

            &::before {
              border-bottom: 1px solid #333;
            }
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        .circle {
          background-color: #646464;
        }

        .leaderboard-row-content {
          .leaderboard-row-name {
            color: #646464;
          }

          .leaderboard-row-border {
            background-image: linear-gradient(to right, white 2%, #646464);
          }

          .leaderboard-row-stats {
            .counter-section > span {
              color: #646464;
            }
          }
        }

        .count {
          span {
            background-color: #646464;

            &::before {
              border-bottom: 1px solid #646464;
            }
          }
        }
      }
    }
  }
}

/* Theme: Sports */
.theme_sports {
  background-image: url(~modules/monitoring/assets/sports/sports.jpg);

  .collapseable {
    option,
    .react-select-box-options,
    .react-select-box-search {
      background-color: #18264b !important;
    }

    .react-select-box-select {
      background-color: #203656 !important;
    }

    .react-select-box-option,
    .react-select-box-select {
      &:hover {
        background-color: #04152b !important;
      }
    }
  }

  * {
    color: #fff !important;
  }

  .content-box-header {
    .labels {
      margin-left: 20px;
      padding: 14px 150px 14px 22px !important;
      width: auto;
      height: 33px;
      line-height: 33px;
      background-image: url(../img/gamification_themes/sports/bg_header.png);
      background-position: center right;

      li {
        font-size: 30px;
      }
    }
  }

  .leaderboard {
    .leaderboard-row {
      .leaderboard-row-content,
      .place-container,
      .place-container-no-avatar {
        background-image: url(../img/gamification_themes/sports/bg_row.png);

        .leaderboard-row-stats {
          .border-icon {
            .border-icon-content {
              background-image: url(../img/gamification_themes/sports/leaderboard_arrow.png);
            }
          }
        }
      }
    }
  }
}

/* Theme: Sports 2 */
.theme_sports_2,
.theme_sports_3,
.theme_city {
  background-image: url(~modules/monitoring/assets/sports/sports-2.jpg);

  .collapseable {
    option,
    .react-select-box-options,
    .react-select-box-search {
      background-color: #282828 !important;
    }

    .react-select-box-select {
      background-color: #333 !important;
    }

    .react-select-box-option,
    .react-select-box-select {
      &:hover {
        background-color: #333 !important;
      }
    }
  }

  * {
    color: #fff !important;
  }

  .content-box-header {
    .labels {
      margin-left: 20px;
      padding: 14px 150px 14px 22px !important;
      width: auto;
      height: 33px;
      line-height: 33px;
      background-image: url(../img/gamification_themes/sports_2/bg_header.png);
      background-position: center right;

      li {
        font-size: 30px;
        color: #000 !important;
      }
    }
  }

  .leaderboard {
    .leaderboard-row {
      height: 74px;
      margin-bottom: 9px;

      .place-container,
      .place-container-no-avatar {
        .circle {
          margin: 5px 0 0 42px !important;
          border: none;
          width: 64px;
          height: 64px;
          font-size: 35px;
          line-height: 1.8;
          font-weight: 400;

          sup {
            top: -17px;
            font-size: 18px;
          }
        }

        .place-avatar {
          .circle {
            width: 64px !important;
            height: 64px !important;

            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }

      .place-container {
        .place-content {
          border: none;

          .circle {
            margin: 5px 0 0 !important;
          }
        }
      }

      .avatar-left .place-content {
        left: 11px;
      }

      .avatar-right .place-content {
        right: 11px;
      }

      .leaderboard-row-wrapper {
        width: calc(100% - 151px);
      }

      .place-container,
      .place-container-no-avatar {
        width: 151px;
      }

      .leaderboard-row-content,
      .place-container,
      .place-container-no-avatar {
        padding: 0;
        height: 74px;
        background-image: url(../img/gamification_themes/sports_2/bg_row.png);

        .leaderboard-row-name {
          padding: 18px 0 0;
          font-weight: 300;
        }

        .leaderboard-row-stats {
          margin-top: 0;

          .border-icon {
            .border-icon-content {
              background-image: url(../img/gamification_themes/sports_2/leaderboard_arrow.png);
            }

            .border-icon-content,
            .divider {
              height: 40px;
            }
          }

          .counter-section {
            margin-top: 8px;

            & > span {
              font-size: 13px;
            }

            .count {
              height: 47px;
              width: 35px;
              line-height: 48px;

              span {
                font-size: 50px;
              }
            }
          }
        }
      }

      &:first-child {
        height: 155px;

        .circle {
          margin: 9px 7px 0 !important;
          border: none;
          width: 137px;
          height: 137px;
          font-size: 83px;
          line-height: 1.7;
          font-weight: 400;

          sup {
            top: -37px;
            font-size: 40px;
          }
        }

        .place-avatar {
          .circle {
            width: 137px !important;
            height: 137px !important;

            img {
              width: 137px;
              height: 137px;
            }
          }
        }

        .avatar-left .place-content {
          left: -4px;
        }

        .avatar-right .place-content {
          right: -4px;
        }

        .leaderboard-row-content,
        .place-container,
        .place-container-no-avatar {
          height: 155px;
          background-image: url(../img/gamification_themes/sports_2/bg_row_big.png);
        }

        .leaderboard-row-name {
          font-size: 60px;
          padding: 43px 0 20px;
          font-weight: 400;
        }

        .leaderboard-row-stats {
          margin-top: 12px;

          .border-icon {
            .border-icon-content {
              background-image: url(../img/gamification_themes/sports_2/leaderboard_arrow_big.png);
            }

            .border-icon-content,
            .divider {
              height: 79px;
            }
          }

          .counter-section {
            margin-top: 10px;

            & > span {
              font-size: 20px;
            }

            .count {
              height: 80px;
              width: 56px;
              line-height: 80px;

              span {
                font-size: 93px;
              }
            }
          }
        }
      }
    }
  }
}

.theme_sports_3 {
  background-image: url(~modules/monitoring/assets/sports/sports-3.jpg);
}

/* Theme: Tech */
.theme_tech {
  background-image: url(~modules/monitoring/assets/tech/tech.jpg);

  .collapseable {
    option,
    .react-select-box-options,
    .react-select-box-search {
      background-color: #000305 !important;
    }

    .react-select-box-select {
      background-color: #14181b !important;
    }

    .react-select-box-option,
    .react-select-box-select {
      &:hover {
        background-color: #0d1e22 !important;
      }
    }
  }

  * {
    color: #fff !important;
  }

  .content-box-header {
    margin-bottom: 42px;

    .labels {
      margin: 0 0 0 22px;
      padding: 0 300px 0 22px;
      width: 288px;
      text-align: center;
      background-image: url(../img/gamification_themes/tech/bg_header.png);
    }
  }

  .leaderboard {
    .leaderboard-row {
      .leaderboard-row-content,
      .place-container,
      .place-container-no-avatar {
        background-image: url(../img/gamification_themes/tech/bg_row.png);

        .leaderboard-row-stats {
          .border-icon {
            .border-icon-content {
              background-image: url(../img/gamification_themes/tech/leaderboard_arrow.png);
            }
          }
        }
      }
    }
  }
}
