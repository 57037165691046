.custom-report {
  @include ipad() {
    min-width: 0;
  }

  .loading-table {
    position: relative;
    min-height: 200px;
    height: 40%;
  }

  .report-title h2 {
    font-size: 2em;
    margin: 15px 0;
  }

  .date-range {
    display: inline-flex;
  }

  .dropzone-container .page-controls {
    padding: 0;
  }

  .custom-select-items {
    clear: both;
  }

  .custom-chart-items-container {
    max-width: 1400px;
    padding: 10px;
    border: none;
    cursor: pointer;

    &.large {
      height: 170px !important;
      min-height: 170px !important;
    }

    .custom-select-items li {
      cursor: initial !important;
      width: 22%;
      display: inline-block;
      margin-right: 10px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      @include ipad-portrait() {
        width: 21%;
      }
    }
  }

  .custom-chart-call-types-container {
    margin-top: 25px;

    .custom-select-items li {
      border: 0;
      background: none;
      background-color: transparent;
      display: inline-block;
      margin: 11px 20px 11px 0;
    }
  }

  .call-log-container {
    display: block;
    width: 100%;
    clear: both;

    .loader-general {
      position: relative;
      top: 50px;
    }
  }

  .call-log-wrapper {
    margin-top: 25px;
    margin-bottom: 50px;
  }

  .custom-report-details {
    margin-top: 25px;
    min-height: 50px;

    .custom-report-details-option {
      cursor: pointer;
    }
  }
}
