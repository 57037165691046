.keyword-groups-container {
  padding-bottom: 34px;

  #keyword-groups-search-container {
    right: 250px;
  }

  .section-title {
    width: 127px;
  }

  label {
    font-weight: 100;
    margin-right: 16px;
  }

  #keyword-groups-group-name {
    width: 346px;
  }

  #keyword-groups-group-description {
    width: 590px;
  }

  .keywords-content {
    display: flex;
    align-items: flex-end;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 1000px;

    .no-keywords-yet {
      width: 1000px;
      font-style: italic;
      font-weight: 100;
    }

    .tagsClass {
      width: 100%;

      .tagItemsContainerClass {
        max-height: 290px;
        overflow-y: auto;

        .tagClass {
          display: inline-block;
          background-color: #f8f8f8;
          border: 1px solid $border;
          font-size: 15px;
          font-weight: 400;
          margin: 0 6px 14px 0;
          padding: 3px 10px;
          border-radius: 5px;

          .removeClass {
            font-size: 18px;
            color: #92cd5b;
            padding-left: 9px;
          }
        }

        .tagGroupClass {
          background-color: #f8b317;
          border: 1px solid #989393;

          .removeClass {
            color: #000;
          }
        }
      }
    }

    .tagInputClass {
      padding-top: 34px;

      .tagInputFieldClass {
        width: 936px;
        border-right: none;
      }
    }

    .add-keyword {
      padding: 0 12px;
      height: 37px;
      line-height: 37px;
      font-size: 26px;
    }

    .keyword-groups-error {
      color: #ff0000;
      font-style: italic;
      padding: 14px 0;
    }

    .keyword-suggestions {
      display: none;
      width: 100%;
      border: 1px solid #d3d3d3;
      border-top: none;

      input {
        border: none;
        padding: 0;
        margin: 0;
        background-color: transparent;
        padding: 7px 0;
        text-indent: 10px;
        width: 100%;
        text-align: left;
        font-size: 16px;

        &:hover {
          background-color: #f8f8f8;
        }
      }
    }

    .keyword-suggestions-show {
      display: block;
    }
  }
}
