@include keyframes(rotate) {
  0% {
    transform: rotate(90deg);
  }

  20% {
    transform: rotate(180deg);
  }

  40% {
    transform: rotate(90deg);
  }
}

.reporting-custom {
  .rotate-overlay {
    display: none;
  }

  //@include ipad-portrait() {
  //  .rotate-overlay {
  //    // cannot use %center-all here otherwise sass will include with other
  //    // placeholder code and will overwrite display:none above globally
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //    height: 100%;
  //    width: 100%;
  //    left: 0;
  //    top: 0;
  //    position: fixed;
  //    z-index: 10000;
  //    background-color: #fff;
	//
  //    img {
  //      transform: rotate(90deg);
  //      animation: rotate 4s infinite 0s ease;
  //      transform-origin: 60% 60%;
  //      position: relative;
  //      display: block;
  //      top: -50px;
  //    }
  //  }
  //}

  .buttons {
    @include tablet() {
      margin: 15px -1% 0;
      display: block;
      float: none;
    }

    .btn {
      width: 80px;

      @include tablet() {
        margin: 0 1%;
        width: 48%;
        box-sizing: border-box;
      }
    }
  }

  h2 {
    font-size: 18px;
    margin: 0;
    font-weight: 300;
  }

  .inline-svg.icon-right {
    height: 22px;
    width: 22px;
    float: right;

    path {
      fill: #6f6f6f !important;
    }

    &.line {
      width: 30px;
    }

    &.stacked-bar {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .selected .inline-svg.icon-right path {
    fill: white !important;
  }

  .inline-svg.icon-left {
    height: 14px;
    width: 14px;
    float: left;
    margin-right: 6px;

    path {
      fill: #909090 !important;
    }
  }

  .hidden {
    visibility: hidden;
    opacity: 0;
  }

  .title {
    border-bottom: none;
  }

  input[type="search"] {
    margin-top: 10px;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    color: #777;
    width: 195px;
  }

  @include placeholder {
    font-style: italic;
  }

  .table-container {
    margin-bottom: 50px;
  }
}

.reporting-custom > section {
  background: url('../img/custombg.png') repeat-x;
  background-color: #f9f9f9;
  margin-bottom: 25px;
}

.reporting-drop-calls,
.reporting-drop-summary,
.reporting-drop-column {
  min-width: 202px;
  margin-right: 21px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.reporting-custom-filters {
  border: solid 1px $border;
  background-color: #fcfcfc;
  padding: 11px;
}

.reporting-custom-filters::after {
  @extend .clearfix;
}

.reporting-custom-filters > section {
  width: 25%;
  float: left;
  font-size: 13px;
  font-family: lato;
  padding: 5px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.1s ease;
  box-sizing: border-box;
}

.reporting-custom-controls {
  float: left;
  width: 230px;
  border: solid 1px $border;
  background-color: #fcfcfc;
  padding: 10px;
  min-height: 500px;

  .accordion__wrap {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.reporting-custom-controls::after {
  @extend .clearfix;
}

.reporting-custom .chart-title {
  border-radius: 0;
  height: 20px;

  @include ipad() {
    width: 360px !important;
  }
}

.reporting-custom > section.reporting-custom-chart {
  margin-left: 280px;
}
