@include keyframes(blink) {
  20% {
    background-color: $hover-bg;
    color: $hover-color;
  }
}
$green: #8dc549;
$red: #ed1c24;
$duration: 0.35s;
$bounce: cubic-bezier(0.375, 1.495, 0.61, 0.78);

.monitoring-gamification {
  margin: 0;
  height: 100%;
  overflow-x: hidden;

  &.theme_default {
    margin-top: 20px;
  }

  .controls {
    font-size: 16px;
    color: #888;
  }

  .react-select-box-container {
    vertical-align: top !important;
  }

  strong {
    font-weight: 400;
    color: #666;
  }

  .count {
    display: inline-block;
    overflow: hidden;
    margin-right: 3px;
    height: 66px;
    width: 42px;
    text-align: center;
    line-height: 68px;
    perspective: 500px;

    @include hw-accelerate();

    span {
      @include hw-accelerate();

      border-radius: 4px;
      background: #333;
      color: white;
      display: block;
      font-size: 57px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      font-weight: 600;
      transform-origin: 0 32px 0;

      &::before {
        border-bottom: 1px solid #333;
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    .top {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      height: 50%;
      overflow: hidden;

      &::before {
        bottom: 0;
      }
    }

    .bottom {
      height: 100%;

      &::before {
        top: 50%;
      }
    }

    &.up {
      &.changing {
        .top.current {
          transition: all $duration * 0.95 ease-in;
          transform: rotate3d(1, 0, 0, -90deg);
        }
      }

      &.changing,
      &.changed, {
        .bottom.next {
          transition: all $duration $bounce $duration;
          transform: rotate3d(1, 0, 0, 0);
        }
      }

      &.changed {
        .top.current,
        .bottom.current {
          display: none;
        }
      }

      .top {
        height: 50%;
      }

      .top.current {
        z-index: 4;
      }

      .top.next {
        z-index: 3;
      }

      .bottom.current {
        z-index: 1;
      }

      .bottom.next {
        transform: rotate3d(1, 0, 0, 90deg);
        z-index: 2;
      }
    }
  }

  .trend-arrow {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    padding: 0;
    background-color: $green;
    margin-left: 20px;

    img {
      position: relative;
      left: 6px;
      padding-top: 4px;
    }

    &.down {
      background-color: $red;

      img {
        top: 4px;
        left: 7px;
        transform: rotate(180deg);
      }
    }
  }

  .table {
    width: 100%;
    margin: 23px 16px 10px;

    th {
      font-weight: 300;
      text-align: left;
      font-size: 14px;
    }

    th:first-child {
      width: 65%;
    }

    th:nth-child(2) {
      width: 10%;
    }

    th:nth-child(3) {
      text-align: center;
      width: 10%;
      position: relative;
    }

    th {
      padding-bottom: 15px;
    }

    td {
      color: #444;
      font-weight: 400;
      font-size: 17px;
      padding: 9px 0;
      border-bottom: 1px solid $border;
    }

    td:last-child {
      border-bottom: none;
      padding: 0;
    }

    td sup {
      margin-left: 2px;
      font-size: 10px;
    }

    tr.changed {
      animation: blink 3s ease-in-out;
    }
  }

  .leaderboard {
    min-height: 500px;
    padding: 20px 0 20px 40px;
    margin-left: -40px;
    overflow-x: hidden;

    .leaderboard-row {
      margin-bottom: 20px;

      > div {
        display: inline-block;
      }

      .place-container {
        position: relative;
        width: 100px;
        height: 100px;
        bottom: 0;

        .place-content {
          position: absolute;
          top: -14px;
          z-index: 1;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #ddd;

          .circle {
            width: 45px;
            height: 45px;
            font-size: 27px;
            box-shadow: none;
            border: 3px solid #fff;

            sup {
              font-size: 12px;
              top: -14px;
            }
          }
        }

        .place-avatar {
          position: absolute;
          overflow: hidden;

          .circle {
            width: 100px !important;
            height: 100px !important;
            background-color: #fff;
            border: 1px solid #ddd;
            box-shadow: none;

            img {
              width: 100px;
              height: 100px;
              border-radius: 50%;
            }
          }
        }
      }

      .avatar-left,
      .avatar-right {
        .circle {
          margin: 0 !important;
          box-shadow: 10px -10px 5px #fff;
          line-height: 1.7;
        }

        .place-content-lg {
          .circle {
            font-size: 25px;

            sup {
              display: none;
            }
          }
        }
      }

      .avatar-left .place-content {
        left: -14px;
      }

      .avatar-right .place-content {
        right: -14px;
      }

      .leaderboard-row-wrapper {
        width: calc(100% - 120px);
        display: inline-block;
        vertical-align: top;

        @include ipad-portrait() {
          width: 550px;
        }

        @include ipad-landscape() {
          width: 800px;
        }
      }

      .leaderboard-row-content {
        height: 100px;
        vertical-align: top;
        margin-left: 15px;
        position: relative;
        transition: width 0.5s ease-in-out;

        .leaderboard-row-avatar {
          display: inline-block;
          background-color: #fff;

          img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
          }
        }

        .leaderboard-row-name {
          display: inline-block;
          white-space: nowrap !important;
          color: #333;
          font-size: 40px;
          font-weight: 800;
          width: 72%;
          margin-left: 15px;
          padding: 27px 0 20px;
          text-transform: uppercase;
          text-overflow: ellipsis;
          overflow: hidden;

          @include ipad-portrait() {
            font-size: 30px;
            padding: 35px 0 20px;
          }
        }

        .leaderboard-row-stats {
          margin-top: 6px;
          padding-right: 5px;
          display: inline-block;
          position: absolute;
          right: 0;

          > div {
            display: inline-block;
          }

          .border-icon {
            display: inline-block;

            .border-icon-content {
              display: inline-block;
              width: 40px;
              height: 64px;
              background-image: url(../img/leaderboard_arrow.png);
              background-position: center left;
              background-repeat: no-repeat;
            }

            .border-icon-content-down {
              background-position: center right;
            }

            .divider {
              display: inline-block;
              width: 1px;
              height: 65px;
              background-color: #ccc;
              margin: 0 20px;

              @include ipad() {
                display: none;
              }
            }

            img {
              position: relative;
              top: -10px;

              @include ipad() {
                display: none;
              }
            }
          }

          .counter-section {
            display: inline-block;
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }

            > span {
              display: block;
              position: relative;
              top: -5px;
              color: #333;
              text-align: center;
              text-transform: uppercase;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }

        .leaderboard-row-border {
          height: 5px;
          width: 100%;
          background-color: #2a99d3;
          border-radius: 7px;
          margin-top: 8px;
        }
      }
    }

    .place-content,
    .place-avatar {
      .circle {
        width: 90px;
        height: 90px;
        text-align: center;
        border-radius: 100%;
        border: 6px solid white;
        background-color: #333;
        box-shadow: 0 0 1px #333;
        line-height: 2;
        font-weight: 600;
        color: white;
        font-size: 47px;

        sup {
          font-size: 30px;
          position: relative;
          top: -13px;
        }
      }

      .circle.blue {
        background-color: #2a99d3;
        text-shadow: 2px 0 3px #2891c8;
      }
    }

    .place-content-lg {
      .circle {
        font-size: 36px;
        line-height: 2.5;

        sup {
          font-size: 18px;
        }
      }
    }
  }

  .leaderboard-table {
    width: 100%;

    .rank {
      .suffix {
        display: inline-block;
        position: relative;
        width: 45px;
        height: 35px;
        border-radius: 5px;
        background-color: #d2d2d2;
      }

      .suffix > span {
        display: inline-block;
        width: 100%;
        color: white;
        font-weight: 400;
        text-align: center;
        padding-top: 6px;

        sup {
          display: inline-block;
          font-size: 12px;
        }
      }

      span {
        font-weight: 300;
      }
    }

    .rank-first {
      @extend .rank;

      .suffix {
        background-color: #8cc24b;
      }

      span {
        font-weight: 400;
      }
    }

    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    th {
      color: #666;
      font-weight: 300;
      text-align: left;
    }

    th:first-child {
      width: 5%;
    }

    th:nth-child(2) {
      width: 80%;
    }

    th:nth-last-child(1) {
      width: 1%;
    }

    td:last-child {
      padding-left: 15px;
    }

    .table-text {
      white-space: nowrap;
    }

    tbody {
      tr:not(:last-child) {
        border-bottom: 1px solid $border;
      }
    }

    .arrow-container {
      position: relative;
      vertical-align: top;
      top: 3px;
      display: inline-block;
    }

    .leaderboard-trend-arrow {
      width: 15px;
      height: 15px;
      position: absolute;
      right: -25px;
      top: -3px;

      &.down {
        top: 3px;
        transform: rotate(180deg);
      }
    }
  }

  .gamification-no-data {
    @extend %center-all;

    margin: 50px;
    font-size: 40px;
    color: #777;
    text-shadow: #333;
  }
}

.gamification-monitoring-controls {
  .button-popover-icon {
    font-size: 16px;
  }
}

.popout {
  .gamification-monitoring-controls {
    padding-top: 20px;
  }
}

.slick-slider {
  .slick-slide-div {
    float: left;
  }
}

.tabbed-controls {
  .grouped-checkbox {
    display: block;
    padding: 0 25px 25px;
  }

  .total-users-input {
    padding: 0 25px 25px;

    input {
      margin-left: 12px;
      width: 50px;
      background-color: transparent;
    }
  }
}
