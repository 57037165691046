@include keyframes(in-zoom) {
  0% {
    transform: scale3d(0.8, 0.8, 1);
  }

  100% {
    transform: none;
  }
}

@include keyframes(out-zoom) {
  0% {
    transform: none;
  }

  100% {
    transform: scale3d(0.8, 0.8, 1);
  }
}

.date-modal-controls {
  margin-bottom: 30px;
  text-align: left;
}

.date-modal {
  margin-left: 10px;
  display: inline;
  position: relative;

  .calendar-icon,
  .buckets-icon {
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
    cursor: pointer;
    z-index: 10;

    path {
      fill: #ccc !important;
    }

    .svg-overlay {
      width: 30px;
      height: 30px;
      left: -7px;
      top: -5px;
    }
  }

  .buckets-icon {
    padding-right: 5px;

    path {
      fill: $hover-color !important;
    }
  }

  img {
    cursor: pointer;
  }

  .modal,
  .modal-buckets {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    left: -227px;
    top: 47px;
    box-shadow: 1px 1px 13px #6a6a6a;
    padding: 25px;
    padding-top: 6px;
    z-index: 110;

    .endDate {
      margin-top: 15px;
    }

    .update {
      margin-top: 25px;
    }

    animation: in-zoom 0.2s ease;
  }

  .modal {
    width: 430px;
  }

  .modal-buckets-title {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .buckets-label {
    font-style: italic;
    font-size: 16px;
    padding-right: 5px;
  }

  .modal-buckets {
    @extend %noselect;

    width: 250px;

    .colon {
      margin: 0 9px;
    }
  }

  .arrow {
    position: relative;
    left: 194px;
    top: -23px;
  }

  label {
    display: inline-block;
    width: 40px;
  }

  .date-input {
    display: inline;

    input {
      cursor: pointer;
      width: 105px;
      margin-left: 20px;
      background: url('../img/calendar.png');
      background-repeat: no-repeat;
      background-position: 98px 10px;
    }

    .react-datepicker-wrapper {
      display: inline-block;
    }
  }

  .time,
  .minutes {
    margin: 0 !important;
    width: 20px;
    outline: none;
  }

  .mins {
    font-size: 18px;
    font-style: italic;
    padding-left: 10px;
    color: #777;
  }

  .plus,
  .minus {
    float: right;
    padding-top: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .plus {
    margin-left: 10px;
  }

  .minus {
    padding-top: 16px;
  }

  .infinity-wrapper {
    position: relative;
  }

  .infinity {
    position: absolute;
    top: -10px;
    cursor: pointer;
    color: #999;
    height: 40px;
    width: 35px;
    font-size: 24px;

    @extend %center-all;

    left: 4px;

    @include ipad() {
      top: -13px;
      font-size: 40px;
    }
  }

  .minutes-bucket {
    width: 33px;
    outline: none;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
    margin-bottom: 10px;
  }

  .colon {
    margin: 0 7px;
  }

  .at {
    margin: 0 14px;
  }

  select {
    background: url('../img/arrow.png');
    background-position: 90% 50%;
    background-repeat: no-repeat;
  }

  .meridiem {
    padding: 0;
    border: none;
    margin-left: 12px;
    padding-right: 26px;
  }

  .relative-dropdown {
    height: 40px;
    vertical-align: top;
    width: 100px;
    background-position: 90% 50%;
    border: 1px solid #ebebeb;
    margin-left: 12px;
    padding-right: 26px;
  }

  .relative-value {
    border: 1px solid #ebebeb;
    height: 40px;
    width: 55px;
  }

  .relative-input {
    width: 35px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }

  .relative-label-prefix {
    color: #777;
    padding-right: 10px;
  }

  .relative-label-suffix {
    color: #777;
    padding-left: 10px;
  }

  input {
    border: 1px solid #ebebeb;
    font-size: 16px;
    padding: 10px;
  }

  .error {
    border: 1px solid #dd8484;
    background-color: #f9d5d8;
  }

  .absolute-label {
    font-size: 17px;
    color: #777;
    margin-bottom: 15px;
  }

  .round {
    padding-top: 8px;
    width: 100% !important;
    color: #777;
  }

  .presets {
    float: left;
    position: relative;
    top: 20px;

    span {
      padding-right: 20px;
      font-size: 15px;
      text-decoration: underline;
      color: $blue;
      cursor: pointer;
    }
  }

  input[type="checkbox"]:disabled {
    background: url(../img/checkmark_disabled.png) no-repeat;
    border-color: #bbb;
  }

  .error-message {
    color: $red;
    margin-bottom: 10px;
  }
}
