@import '@nextiva/next-ui/styles/colors';

.volume {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    .icon {
      opacity: 1;
    }

    .wrapper {
      opacity: 1;
      visibility: visible;
      
      .line {
        transform: translateX(0);
        transition: transform .5s ease;
      }
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  opacity: .75;
  font-size: 20px;
  transition: opacity .3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: color(nav-blue);
  }
}

.zero {
  opacity: 1;

  polygon {
    opacity: .85;
    fill: color(nav-blue);
  }

  path {
    fill: color(bittersweet-red);
  }
}

.wrapper {
  position: absolute;
  left: 100%;
  top: 50%;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 18px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  transition: opacity .3s ease;
  overflow: hidden;
}

.line {
  width: 100px;
  height: 5px;
  background: color(mercury);
  border-radius: 5px;
  transition: all .3s ease;
  transform:  translateX(-100%);
  cursor: pointer;
}

.progress {
  position: absolute;
  height: 5px;
  border-radius: 5px;
  background: color(nav-blue);
}

.value {
  position: relative;
  top: -4px;
  left: -3px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  transition: background .3s ease;
  box-shadow: 0 0 3px -1px #000;
  z-index: 1;

  &:active {
    background: color(cool-gray-1);
  }
}