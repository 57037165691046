.chart-dropdown {
  @extend %noselect;

  display: inline-block;
  cursor: pointer;
  position: relative;

  .svg-smooth-line,
  .svg-line {
    width: 30px !important;
  }

  .option .svg-smooth-line,
  .option .svg-line {
    width: 12px !important;
    height: 12px !important;
    top: 5px;
    padding-right: 35px !important;
  }

  .inline-svg {
    height: 17px;
    width: 17px;
    display: inline-block;

    path {
      fill: #ccc !important;
    }

    .svg-overlay {
      width: 40px;
      height: 30px;
      left: -6px;
      top: -5px;
    }
  }

  .triangle {
    vertical-align: top;
    position: relative;
    bottom: 3px;
    margin-left: 5px;
    height: 20px !important;
    width: 13px !important;

    path {
      fill: #ddd !important;
    }
  }

  .options {
    opacity: 0.99;
    z-index: 101;
    top: 30px;
    position: absolute;
    width: 200px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
  }

  .option {
    text-align: left;

    .text {
      font-weight: 300;
      padding-top: 2px;
      display: inline-block;
      color: #666;
      font-size: 15px;
      vertical-align: top;
    }

    .inline-svg {
      float: right;
      padding-right: 20px;

      path {
        fill: #bbb !important;
      }
    }

    padding: 10px 0 10px 15px;
  }

  img {
    display: inline-block;
  }

  .option:last-child {
    border-bottom: none;
  }

  .option:hover {
    background-color: $hover-bg;

    .inline-svg path {
      fill: $hover-color !important;
    }

    .text {
      color: $hover-color;
    }
  }
}
