.analytics-custom {
  h2 {
    font-size: 20px;
    margin: 0;
    font-weight: 300;
  }

  .inline-svg.icon-right {
    height: 22px;
    width: 22px;
    float: right;

    path {
      fill: #6f6f6f !important;
    }

    &.line {
      width: 30px;
    }
  }

  .selected .inline-svg.icon-right path {
    fill: white !important;
  }

  .inline-svg.icon-left {
    height: 14px;
    width: 14px;
    float: left;
    margin-right: 6px;

    path {
      fill: #909090 !important;
    }
  }

  .hidden {
    visibility: hidden;
    opacity: 0;
  }

  .title {
    border-bottom: none;
  }

  input[type="search"] {
    margin-top: 10px;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    color: #777;
    width: 195px;
  }

  @include placeholder {
    font-style: italic;
  }

  .receiver {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-left: 40px;
    width: 250px;

    h2 {
      text-align: left;
    }

    .area {
      border: 3px dashed $border;
      display: block;
      margin: 15px 3px;
      padding: 5px;

      &.hover,
      &:hover {
        background-color: $hover-bg;
        border: 3px dashed $hover-color;
        cursor: pointer;
      }

      min-height: 65px;
    }
  }

  .custom-chart-container {
    height: 500px;
    background-color: white;
    border: 1px solid $input-border;
    padding: 10px 20px;
    margin-bottom: 10px;

    .custom-chart-controls {
      padding-bottom: 10px;
      border-bottom: 1px solid $border;

      > * {
        display: inline-block;
      }

      > input {
        width: 400px;
      }
    }
  }
}

.analytics-custom .analytics-custom-container {
  background: url('../img/custombg.png') repeat-x;
  background-color: #f9f9f9;
  margin-bottom: 25px;
}

.analytics-custom-container {
  border: solid 1px $border;
  background-color: #fcfcfc;
  padding: 11px;
}

.analytics-custom-controls {
  display: inline-block;
  width: 230px;
  padding: 10px;
  min-height: 500px;
}

.analytics-custom-controls::after {
  @extend .clearfix;
}

