@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';
@import '~@nextiva/next-ui/styles/colors';

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body,
html {
  height: 100%;
}

.clearfix {
  content: "";
  display: table;
  clear: both;
}

body {
  font-size: 16px;
  font-weight: 300;
  font-family: "lato";
  color: #333;
}

strong {
  font-weight: 400 !important;
}

h1 {
  font-weight: 100;
  font-size: 1.25em;
}

li {
  list-style-type: none;
}

main {
  width: 1240px;
  padding: 20px 20px 0;
  margin: auto;
  box-sizing: border-box;

  @include ipad-landscape() {
    width: 1024px;
  }

  @include ipad-portrait() {
    width: 768px;
  }
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

input[type="text"],
input[type="number"] {
  border: 1px solid $input-border;
  font-size: 16px;
  padding: 10px;
  border-radius: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container {
  clear: both;
  background-color: white;
  border: 1px solid $border;
  border-top: none;
  padding: 7px 27px;
  font-size: 1.2em;
  min-height: 90px;
}

.container::after,
.row::after {
  @extend .clearfix;
}

.light {
  color: #888 !important;
}

th {
  font-weight: 400;
}

.content-box {
  position: relative;
  min-height: 400px; //TODO: need to refactor
  border: 1px solid $border;
  margin: 20px 0 0;
  padding: 22px;
}

.content-box-header {
  border-bottom: 1px solid $border;
  padding-bottom: 16px;
  margin-bottom: 10px;

  .labels {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      color: #444;
      font-weight: 300;
      font-size: 20px;
      padding: 0 15px;
      display: inline-block;
      border-right: 1px solid $border;
    }

    li:first-child {
      padding-left: 0;
    }

    li:last-child {
      padding-right: 0;
      border-right: none;
    }
  }

  .controls {
    float: right;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      cursor: pointer;
      display: inline-block;
      padding: 0 10px;
    }

    li:last-child {
      padding-right: 0;
    }
  }
}

.title {
  border-bottom: 1px solid $border;
}

.center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.page-title {
  position: relative;

  .add-btn {
    position: absolute;
    top: -9px;
    right: 0;
    height: 39px;
  }

  .search-container {
    position: absolute;
    top: -9px;
    right: 0;

    input {
      width: 169px;
    }
  }
}

.section-row {
  @include flexbox;
  @include flex-direction(row);

  justify-content: space-between;
  padding: 34px 0;
}

.section-buttons {
  justify-content: flex-end;

  button {
    margin-left: 20px;
  }
}

.label-block {
  display: block;
  margin-bottom: 12px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
  clear: both;
  display: block;

  > * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .col {
    float: left;
    padding: 0 15px;
  }
}

.row::before {
  content: " ";
  display: table;
}

.row::after {
  clear: both;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.row.reports .col-full {
  margin-top: 20px;
}

.reports {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
  padding-top: 8px;

  .col-full,
  .col-half {
    padding: 10px;
    box-sizing: border-box;
  }

  .col-half {
    width: 50%;

    @include desktop() {
      width: 100%;
    }
  }

  .col-full {
    width: 100%;
  }
}

.nx-spinner-svg {
  margin: 0 !important;

  @include center-position();
}

.bold {
  font-weight: 400;
}

.error {
  border: 1px solid #ffa8a8 !important;
  background-color: #fff8f8;
}

.field-container {
  width: 250px;
  float: left;

  > div:first-child {
    font-weight: 300;
    font-size: 1em;
    color: #aaa;
    margin-bottom: 20px;
  }

  select,
  input {
    width: 100%;
  }
}

input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
}

input[type="checkbox"] {
  outline: none !important;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 1px solid $input-border;
  background: #fff;
}

input[type="checkbox"]:checked {
  outline: none;
  background: url(../img/checkmark.png) no-repeat;
  border-color: #8cc64b;
}

input[type="radio"] {
  background: url(../img/radio_inactive.png) no-repeat;
  cursor: pointer;
  height: 21px;
  outline: none;
  width: 21px;
}

input[type="radio"]:checked {
  background: url(../img/radio_active.png) no-repeat;
  outline: none;
}

.css-arrow-down {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0;
  border-color: #ccc transparent transparent;
}

.arrow-up svg {
  transform: rotate(180deg);
}

.arrow-right svg {
  transform: rotate(-90deg);
}

.arrow-left svg {
  transform: rotate(90deg);
}

.inline-svg {
  display: inline-block;
  position: relative;
  overflow: hidden;

  .svg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    left: 0;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

input[type="search"],
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  cursor: pointer;
  border: 1px solid $input-border;
  border-radius: 0 !important;
  line-height: 23px !important;
  padding: 7px 10px;
  text-indent: 2px;
  text-overflow: '';
  color: #666;
  font-size: 15px;
}

select::-ms-expand {
  display: none;
}

select {
  padding-right: 20px;
  background: url('../img/arrow.png');
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-color: transparent;
}

input[type="search"] {
  background: url('../img/search.png');
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-right: 20px;
}

input[type="checkbox"]:disabled {
  background-color: #f1f1f1;
  border-color: #bbb;
}

.popout-button {
  width: 18px;
  height: 18px;
  cursor: pointer;

  path {
    fill: #bfbfbf !important;
  }
}

%center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

@include ipad() {
  .ipad-break {
    display: block;
  }
}

.refresh-icon {
  width: 17px;
  height: 17px;

  path {
    fill: #aaa !important;
  }
}

.collapseable {
  @extend %noselect;

  transition: height 0.3s ease;
}

.collapse-button {
  transition: all 0.2s ease;

  @extend %noselect;

  cursor: pointer;
  position: absolute;
  height: 18px;
  width: 18px;
  right: 20px;
  top: 15px;

  &.closed {
    -webkit-perspective: 10000px;
    perspective: 10000px;
    transform-origin: 0% 50%;
    transform: rotateX(-180deg);
  }

  path {
    fill: #ccc !important;
  }
}

.global-date-settings-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;

  .global-date-settings {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 1205px;

    .date-range {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .datetime {
        display: block;
        text-align: right;
        line-height: 24px;
        margin-right: 14px;
      }
    }

    .date-modal {
      display: block;
      padding-top: 9px;
    }

    @include ipad-portrait() {
      width: 768px;
    }

    @include ipad-landscape() {
      width: 1024px;
    }
  }
}

.standard-checkbox {
  input[type="checkbox"] {
    vertical-align: middle;
    margin: 0 5px;
  }

  label {
    color: #888;
    font-size: 17px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.no-data {
  @extend %center-all;

  padding: 50px;
  font-size: 40px;
  color: #777;
}

.date-range-popover {
  input {
    border: 0;
    padding: 3px 9px;
    font-size: 14px;
  }
}
