.show-more {
  color: #3d85d1;
}

.collapsed {
  height: 3em;
  line-height: 1em;
  overflow: hidden;
  white-space: wrap;
  display: block;

  p:only-child {
    margin: 0;
  }
}

.not-collapsed {
  height: auto;
  line-height: normal;

  p:only-child {
    margin: 0;
  }
}
