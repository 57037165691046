.settings-reporting {
  position: relative;
  margin-top: 10px;

  @include ipad() {
    // User needs to be able to see dropdowns when expanded
    padding-bottom: 200px;
  }

  .row {
    padding-left: 15px;
  }

  .real-time {
    margin-right: 10px;
  }

  .title {
    margin-bottom: 30px;
  }

  .settings-table .row > div {
    display: inline-block;
  }

  .settings-table .row > div:first-child {
    padding-left: 60px;
    width: 240px;

    @include ipad-landscape() {
      width: 150px;
      padding-left: 0;
    }

    @include ipad-portrait() {
      font-weight: 400;
      width: 200px;
      padding-left: 0;
      display: block;
      margin-top: 40px;
      margin-bottom: 10px;
    }
  }

  .settings-table .row > div:nth-child(2) {
    width: 500px;

    @include ipad-portrait() {
      width: 350px;
    }

    @include ipad-landscape() {
      width: 450px;
    }
  }

  .row {
    margin-bottom: 25px;

    @include ipad-portrait() {
      margin-bottom: 10px;
    }
  }

  .field-container {
    select {
      font-size: 0.8em;
    }

    margin-right: 55px;
  }

  .subheader {
    margin: 0;
    left: 58px;
    position: absolute;
    color: #555;
  }

  .settings-time {
    margin-top: 50px;
    padding-top: 40px;
    border-top: 1px solid $border;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }

  .date-range-container {
    & > label,
    .date-range {
      display: inline-block;
    }

    & > label {
      width: 160px;
      font-weight: 400;
    }

    margin-top: 20px;
  }

  .report-settings-container {
    margin-top: 20px;
  }

  section {
    margin-bottom: 50px;
  }

  .report-settings-container {
    > label {
      display: inline-block;
      font-weight: 400;
      vertical-align: middle;
    }

    .standard-checkbox {
      vertical-align: middle;
    }

    .xbert-tooltip {
      margin-left: 5px;

      img {
        vertical-align: middle;
      }
    }
  }
}
