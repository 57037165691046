

.dateRange {
  :global(.nx-popover-target) {
    &:first-child {
      margin-left: 0;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }
}
