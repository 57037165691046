.monitoring-scorecards-container {
  position: relative;

  .type-combo-filter > * {
    display: inline-block;
    vertical-align: middle;
  }

  .react-select-box-container {
    vertical-align: top;
  }

  .type-combo-filter select {
    font-size: 16px;
    color: #666;
  }

  #design {
    display: none;
    position: absolute;
    opacity: 0;
    top: -35px;
    left: -34px;
  }

  .summary,
  .detail {
    display: none;
  }

  .summary.active,
  .detail.active {
    display: block;
  }

  .content-box-header .controls {
    li {
      padding: 0 34px 0 0;
      line-height: 46px;
      font-size: 18px;
      color: #3d85d1;
      letter-spacing: 0.2px;
    }

    li.active {
      color: #444;
    }
  }
}

.monitoring-scorecards-container .content-box-header {
  margin-top: 20px;
}

@mixin header($headerClass, $svg) {
  &#{$headerClass} {
    .fattable-header-container div {
      & > div {
        @content;
      }

      & div {
        padding-top: 10px;
      }

      .header-icon::before {
        background: url(/svg/#{$svg}.svg) no-repeat;
      }
    }
  }
}

.scorecards-table {
  table-layout: fixed;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 32px !important;
  font-family: lato;
  color: #333;

  tr.bold td {
    font-weight: 600;
  }

  .fattable-header-container div {
    font-size: 17px;
    color: white;

    & div {
      border-right: 1px solid white;
    }
  }

  .header-icon {
    text-transform: uppercase;
    padding-left: 40px !important;

    &::before {
      position: absolute;
      top: 10px;
      left: 10px;
      content: '';
      display: inline-block;
      background-size: 22px 22px;
      width: 22px;
      height: 22px;
    }
  }

  @include header('.scorecard-totals', 'briefcase') {
    background-color: #b3b3b3;
  }

  @include header('.scorecard-inbound', 'inbound_calls') {
    background-color: #ffa72d;
  }

  @include header('.scorecard-outbound', 'outbound_calls') {
    background-color: #2a99d3;
  }

  @include header('.scorecard-average-talk', 'talk_time') {
    background-color: #b6e12d;
  }

  @include header('.scorecard-unique-call-ratio', 'unique_calls') {
    background-color: #d22881;
  }

  @include header('.scorecard-unique-dials', 'unique_calls') {
    background-color: #952cd5;
  }

  @include header('.scorecard-missed', 'missed_calls') {
    background-color: #fe2a27;
  }

  @include header('.scorecard-longest-duration', 'longest_call') {
    background-color: #3f2bd5;
  }

  sup {
    font-size: 10px;
    margin-left: 3px;
  }
}

.monitoring-scorecards-table-summary {
  font-weight: bold;
}

@mixin monitoring-scorecards-table-color($color) {
  thead th,
  thead td {
    background-color: $color;
  }

  thead > tr > th:first-child,
  thead > tr > td:first-child {
    border-left-color: $color;
  }

  thead > tr > th:last-child,
  thead > tr > td:last-child {
    border-right-color: $color;
  }
}

.monitoring-scorecards-table-a {
  @include monitoring-scorecards-table-color(#b3b3b3);
}

.monitoring-scorecards-table-b {
  @include monitoring-scorecards-table-color(#ffa536);
}

.monitoring-scorecards-table-c {
  @include monitoring-scorecards-table-color(#2498d1);
}

.summary .monitoring-scorecards-table tbody tr:first-child th,
.summary .monitoring-scorecards-table tbody tr:first-child td {
  font-weight: inherit;
}

.scorecards-monitoring-controls {
  .button-popover-icon {
    font-size: 14px;
  }
}
