
.landing {
  display: block;
  margin: 5vh auto;
}

.row {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
