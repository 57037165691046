@import 'styles/mixins';

.analytics-period {
  @include tablet() {
    flex-wrap: wrap;
  }

  .dates .divider {
    padding: 1px;
  }

  .react-select-box-container {
    margin-right: 15px;
  }

  .dates {
    font-size: 15px;
  }

  .over {
    font-size: 15px;
    color: #999;
    padding: 10px;

    @include tablet() {
      padding: 5px 8px;
    }
  }

  .button-popover-container {
    @include tablet() {
      margin-left: 0;
    }

    .btn-transparent {
      padding-right: 0;
    }
  }

  select {
    @include tablet() {
      margin-bottom: 15px;
    }
  }
}
