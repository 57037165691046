@import '../mixins.scss';
@import '../colors.scss';

.user_activity, .analytics{
    .reports{
        .chart-container{
            .chart-header{
                width: 98%;
                .popout-element {
                    height: 16px;
                    width: 17px;
                }
            }
        }
        // Table contents
        .connected-calls-container{
            .connected-calls-header{
                display: flex;
                align-items: center;
                .title-content{
                    h4{
                        font-size: 18px;
                        font-weight: normal;
                    }
                }
                .quality-types{
                    margin-left: auto;
                    ul{
                        display: flex;
                        gap: 2.5em;
                        li{
                            position: relative;
                            &::before{
                                content: '';
                                height: 14px;
                                width: 14px;
                                left: -20px;
                                top:1px;
                                position: absolute;
                            }
                            &.good{
                                &::before{
                                    background-color: $good;
                                }
                            }
                            &.moderate{
                                &::before{
                                    background-color: $moderate;
                                }
                            }
                            &.poor{
                                &::before{
                                    background-color: $poor;
                                }
                            }
                        }
                    }
                }
            }
            .log-list{
                background: #F7F8FA;
                border-radius: 5px;
                padding: 4px;
                .nav-control{
                    ul{
                        display: flex;
                        gap: 2.5em;
                        margin: 0 atuo;
                        margin: 0;
                        padding: 0 20px;
                        cursor: pointer;
                        
                        li{
                            padding: 15px 0;
                            font-size: 16px;
                            font-weight: 400;
                            color: #54687F;
                            &.active{
                                color: #4B7FD0;
                                font-weight: 700;
                                border-bottom: 2px solid #4B7FD0;
                            }
                        }
                    }
                }
            }
        }
    }
}