@keyframes vex-fadein {
  /* line 9, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 11, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes vex-fadein {
  /* line 9, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 11, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-moz-keyframes vex-fadein {
  /* line 9, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 11, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-ms-keyframes vex-fadein {
  /* line 9, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 11, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-o-keyframes vex-fadein {
  /* line 9, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 11, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@keyframes vex-fadeout {
  /* line 16, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
  }

  /* line 18, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes vex-fadeout {
  /* line 16, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
  }

  /* line 18, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
  }
}

@-moz-keyframes vex-fadeout {
  /* line 16, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
  }

  /* line 18, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
  }
}

@-ms-keyframes vex-fadeout {
  /* line 16, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
  }

  /* line 18, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
  }
}

@-o-keyframes vex-fadeout {
  /* line 16, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
  }

  /* line 18, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
  }
}

@keyframes vex-rotation {
  /* line 127, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 129, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-webkit-keyframes vex-rotation {
  /* line 127, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 129, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-moz-keyframes vex-rotation {
  /* line 127, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 129, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-ms-keyframes vex-rotation {
  /* line 127, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 129, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-o-keyframes vex-rotation {
  /* line 127, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 129, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

/* line 11, ../sass/vex.sass */
.vex, .vex *, .vex *:before, .vex *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 14, ../sass/vex.sass */
.vex {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1111;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* line 25, ../sass/vex.sass */
.vex-overlay {
  background: #333333;
  filter: alpha(opacity=20);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

/* line 30, ../sass/vex.sass */
.vex-overlay {
  animation: vex-fadein 0.5s;
  -webkit-animation: vex-fadein 0.5s;
  -moz-animation: vex-fadein 0.5s;
  -ms-animation: vex-fadein 0.5s;
  -o-animation: vex-fadein 0.5s;
  -webkit-backface-visibility: hidden;
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* line 39, ../sass/vex.sass */
.vex.vex-closing .vex-overlay {
  animation: vex-fadeout 0.5s;
  -webkit-animation: vex-fadeout 0.5s;
  -moz-animation: vex-fadeout 0.5s;
  -ms-animation: vex-fadeout 0.5s;
  -o-animation: vex-fadeout 0.5s;
  -webkit-backface-visibility: hidden;
}

/* line 42, ../sass/vex.sass */
.vex-content {
  animation: vex-fadein 0.5s;
  -webkit-animation: vex-fadein 0.5s;
  -moz-animation: vex-fadein 0.5s;
  -ms-animation: vex-fadein 0.5s;
  -o-animation: vex-fadein 0.5s;
  -webkit-backface-visibility: hidden;
  background: white;
}
/* line 46, ../sass/vex.sass */
.vex.vex-closing .vex-content {
  animation: vex-fadeout 0.5s;
  -webkit-animation: vex-fadeout 0.5s;
  -moz-animation: vex-fadeout 0.5s;
  -ms-animation: vex-fadeout 0.5s;
  -o-animation: vex-fadeout 0.5s;
  -webkit-backface-visibility: hidden;
}

/* line 49, ../sass/vex.sass */
.vex-close:before {
  font-family: Arial, sans-serif;
  content: "\00D7";
}

/* line 53, ../sass/vex.sass */
.vex-dialog-form {
  margin: 0;
}

/* line 56, ../sass/vex.sass */
.vex-dialog-button {
  -webkit-appearance: none;
  cursor: pointer;
}

/* line 60, ../sass/vex.sass */
.vex-loading-spinner {
  animation: vex-rotation 0.7s linear infinite;
  -webkit-animation: vex-rotation 0.7s linear infinite;
  -moz-animation: vex-rotation 0.7s linear infinite;
  -ms-animation: vex-rotation 0.7s linear infinite;
  -o-animation: vex-rotation 0.7s linear infinite;
  -webkit-backface-visibility: hidden;
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1112;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2em;
  width: 2em;
  background: white;
}

/* line 76, ../sass/vex.sass */
body.vex-open {
  overflow: hidden;
}

@keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-webkit-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-moz-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-ms-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-o-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-webkit-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-moz-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-ms-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-o-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-webkit-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-moz-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-ms-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-o-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

/* line 13, ../sass/vex-theme-default.sass */
.vex.vex-theme-default {
  padding-top: 160px;
  padding-bottom: 160px;
}
/* line 17, ../sass/vex-theme-default.sass */
.vex.vex-theme-default.vex-closing .vex-content {
  animation: vex-flyout 0.5s;
  -webkit-animation: vex-flyout 0.5s;
  -moz-animation: vex-flyout 0.5s;
  -ms-animation: vex-flyout 0.5s;
  -o-animation: vex-flyout 0.5s;
  -webkit-backface-visibility: hidden;
}
/* line 20, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-content {
  animation: vex-flyin 0.5s;
  -webkit-animation: vex-flyin 0.5s;
  -moz-animation: vex-flyin 0.5s;
  -ms-animation: vex-flyin 0.5s;
  -o-animation: vex-flyin 0.5s;
  -webkit-backface-visibility: hidden;
}
/* line 23, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background: #fff;
  color: #444444;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 528px;
  font-size: 1.1em;
  line-height: 1.5em;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px #ddd;
  background-position-x: 22px;
}

.vex.vex-theme-default .vex-content.error {
  background-image: url(../img/modal_error.png);
  background-position-y: 12px;
  border: 1px solid #ed1c24;
}

.vex.vex-theme-default .vex-content.success {
  background-image: url(../img/modal_success.png);
  background-position-y: 14px;
  border: 1px solid #8dc549;
}

.vex.vex-theme-default .vex-content.warning {
  background-image: url(/assets/img/modal_warning.png);
  background-position-y: 28px;
  border: 1px solid #f4ad1b;
}

.vex.vex-theme-default .vex-content ul {
  list-style-type: disc;
  margin: 10px;
}

/* line 36, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-content h1, .vex.vex-theme-default .vex-content h2, .vex.vex-theme-default .vex-content h3, .vex.vex-theme-default .vex-content h4, .vex.vex-theme-default .vex-content h5, .vex.vex-theme-default .vex-content h6, .vex.vex-theme-default .vex-content p, .vex.vex-theme-default .vex-content ul, .vex.vex-theme-default .vex-content li {
  color: inherit;
}
/* line 39, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-close {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
/* line 46, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-close:before {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  content: "\00D7";
  font-size: 26px;
  font-weight: normal;
  line-height: 31px;
  height: 30px;
  width: 30px;
  text-align: center;
  top: 3px;
  right: 3px;
  color: #bbbbbb;
  background: transparent;
}
/* line 61, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-close:hover:before, .vex.vex-theme-default .vex-close:active:before {
  color: #777777;
  background: #e0e0e0;
}
/* line 67, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-form .vex-dialog-message {
  padding: 10px 30px 10px 56px;
  margin-bottom: 0.5em;
}
/* line 70, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-form .vex-dialog-input {
  margin-bottom: 1em;
}
/* line 73, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-form .vex-dialog-input textarea, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="date"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="datetime"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="datetime-local"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="email"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="month"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="number"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="password"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="search"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="tel"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="text"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="time"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="url"], .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="week"] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: white;
  width: 100%;
  padding: 0.25em 0.67em;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  min-height: 2.5em;
  margin: 0 0 0.25em;
}
/* line 85, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-default .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px #8dbdf1;
  -moz-box-shadow: inset 0 0 0 2px #8dbdf1;
  box-shadow: inset 0 0 0 2px #8dbdf1;
  outline: none;
}
/* line 89, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-form .vex-dialog-buttons {
  *zoom: 1;
}
/* line 38, ../../../../../.rvm/gems/ruby-1.9.3-p194/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/utilities/general/_clearfix.scss */
.vex.vex-theme-default .vex-dialog-form .vex-dialog-buttons:after {
  content: "";
  display: table;
  clear: both;
}
/* line 92, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-button {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 0;
  float: left;
  margin: 0 0 14px 59px;
  font-family: latolight, helvetica, arial, sans-serif;
  font-size: 18px;
  line-height: 1em;
  padding: 0;
}
/* line 104, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-button.vex-last {
  margin-left: 57px;
}
/* line 107, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-button:focus {
  animation: vex-pulse 1.1s infinite;
  -webkit-animation: vex-pulse 1.1s infinite;
  -moz-animation: vex-pulse 1.1s infinite;
  -ms-animation: vex-pulse 1.1s infinite;
  -o-animation: vex-pulse 1.1s infinite;
  -webkit-backface-visibility: hidden;
  outline: none;
}
@media (max-width: 568px) {
  /* line 107, ../sass/vex-theme-default.sass */
  .vex.vex-theme-default .vex-dialog-button:focus {
    animation: none;
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    -o-animation: none;
    -webkit-backface-visibility: hidden;
  }
}
/* line 116, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-button.vex-dialog-button-primary {
  background: #fff;
  color: #f8b317;
}
/* line 120, ../sass/vex-theme-default.sass */
.vex.vex-theme-default .vex-dialog-button.vex-dialog-button-secondary {
  background: #fff;
  color: #bfbfbf;
}

/* line 124, ../sass/vex-theme-default.sass */
.vex-loading-spinner.vex-theme-default {
  -webkit-box-shadow: 0 0 0 0.5em #f0f0f0, 0 0 1px 0.5em rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 0 0.5em #f0f0f0, 0 0 1px 0.5em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 0.5em #f0f0f0, 0 0 1px 0.5em rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background: #f0f0f0;
  border: 0.2em solid transparent;
  border-top-color: #bbbbbb;
  top: -1.1em;
  bottom: auto;
}
