.color-picker {
  .swatch {
    padding: 5px;
    background-color: #fff;
    border-radius: 1px;
    display: inline-block;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  }

  .swatch-color {
    width: 18px;
    height: 18px;
  }
}
