.platform-filters-popover {
  .Popover-body {
    border-radius: 6px;
    padding-left: 0;
  }
}

.platform-filters {
  display: flex;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  &--row {
    display: flex;
    align-items: center;
  }
}

.multi-select-platform {
  width: 380px;
  height: 375px;

  &__search {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-bottom: 14px;
  }

  &__scrollbar {
    overflow-y: auto;
    max-width: 366px;
    max-height: 200px;
  }

  &__scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &__scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__items {
    margin: 0;
    padding-bottom: 40px;
    padding-left: 16px;
  }

  &__title {
    align-items: flex-start;
    padding-left: 28px;
    padding-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    color: #c3c3c3;
  }

  &--top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-bottom: 14px;
  }

  &__toggle {
    font-weight: 500;
    font-size: 12px;
    color: #c3c3c3;
    padding-right: 14px;
  }

  &__button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  &__button {
    border-radius: 4px;

    &--right {
      margin-left: 18px;
    }
  }

  input[type="search"] {
    width: 310px;
    justify-content: center;
  }

  input[type="checkbox"] {
    border-radius: 3px;
    padding-left: 0;
    margin: 0;
  }

  .multi-select-item {
    &:not(:last-of-type) {
      border-bottom: none;
    }
  }
}
