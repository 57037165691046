@import 'styles/mixins';

.settings-questions {
  padding: 0 20px 20px;
}

.questions-create {
  width: 100%;
}

.questions-form {
  width: 100%;

  &__row {
    display: flex;
    padding: 30px 0;
  }

  &__label {
    color: #aaa;
    display: block;

    &--section {
      width: 15%;
    }

    &--questions {
      align-self: flex-start;
    }
  }

  &__tooltip {
    margin-left: 4px;
  }

  &__input {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  &__select {
    display: block;
  }

  &__divider {
    flex-grow: 1;
    background-color: #9f9f9f;
    height: 1px;
  }

  &__questions {
    margin: 0;
    padding: 0;
    width: 85%;
  }

  &__question {
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    padding: 15px 0;

    &--first {
      padding-top: 0;
    }
  }

  &__question-type {
    width: 20%;

    select {
      text-transform: uppercase;
      width: 100%;
    }
  }

  &__question-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
  }

  &__question-text {
    width: 60%;

    @include tablet() {
      width: 50%;
    }
  }

  &__add {
    color: $green;
    list-style: none;

    &:not(:first-child) {
      padding: 15px 0;
    }
  }

  &__delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn:not(:last-of-type) {
      margin-right: 12px;
    }

    &--question {
      justify-content: flex-start;
      width: 10%;
    }
  }

  &__name {
    margin-right: 10px;
    width: 25%;
  }

  &__description {
    width: 60%;
  }

  &__question-content {
    padding: 15px 0;
  }

  &__question-options {
    display: flex;
    padding: 20px 0;

    div + div {
      margin-left: 10px;
    }
  }

  &__radio-group {
    display: flex;
  }

  &__radio-group-edit {
    display: flex;
    padding: 15px 0;
  }

  &__radio {
    display: flex;
    align-items: center;
    margin-right: 10px;

    input {
      margin-right: 4px;
    }
  }

  &__collapse-arrow {
    top: 26px;

    &--first {
      top: 10px;
    }
  }

  &__error {
    display: flex;
    flex-flow: column nowrap;
    color: $red;
    padding-top: 4px;
  }

  .collapsible {
    border-bottom: 1px solid $border;
    position: relative;
  }

  .collapsible__content {
    padding-bottom: 20px;
  }
}

.questions-list {
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__new-question {
    align-self: flex-end;
  }

  h1 {
    margin: 0;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}

.call-rating-iteration-arrows {
  text-align: right;
  color: #aaa;

  .btn {
    margin-right: 0.25rem;
  }

  .icon-custom-chevron-left-hidden {
    visibility: hidden;
  }

  .icon-custom-chevron-right-hidden {
    visibility: hidden;
  }

  .disabled {
    opacity: 0.65;
  }
}

.question-responses-list {
  .collapsible {
    border-bottom: 1px solid $border;
    position: relative;

    &__header {
      height: 36px;
      padding-top: 12px;
    }
  }
}
