.Popover-body {
  display: flex;
  flex-direction: column;
  $blue-link: #3d85d1;

  background: #fff;
  box-shadow: 1px 1px 13px #6a6a6a;
  color: #000;
  padding: 0px;

  .tabbed-controls{

    .react-tabs{
    height: calc(100% - (-224px + 224px));
    }
  }

  .group-link-large a {
    color: $blue-link;
    font-size: 16px;
    padding-left: 44px;
  }

  .popover-body-calls{
    line-height: 30px;
    background: #fff;
    font-family: 'lato';
    font-size: 16px;
    cursor: pointer;
    width:308px;

    .questions-form__radio-group{
      padding: 5px;
      margin-left: 20px;

      .questions-form__radio{
      padding:2px;
      }
    }
      .horizontal-tab{
        padding: 5px;

      &:hover {
        background-color: lightgray;
      }

      .selected{
        background-color: white;
        border: solid 1px grey;
          }
      }

    .hideTabs{
      display: none;
    }
    .visibleTabs{
      display: block;
    }

      span.tabIcons {
        margin: 10px;
        .locations{
          margin-top: 6px;
        }
        .callIcon{
          transform: rotate(254deg);
          position: relative;
          bottom: -5px;
        }
        .phoneIcon{
          transform: rotate(254deg);
          position: relative;
          margin-top: 5px;
          height:16px;
          width:20px;
        }
        .internal{
          width:23px;
          height:23px;
          margin-top: 5px;
        }
        .answered{
          margin-top: 6px;
        }
      }

      .multi-select-toggle{
        background-color: #fff;
      }
      .horizontalCallsTab{
        margin: 5px;
        position: absolute;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        font-size: 16px;
        color: #666666 !important;
      }

      .divider{
        border: 1px solid #E3E3E3;
      }

      [type=checkbox] {
        color: #005FEC !important;
        vertical-align: middle;
        -webkit-appearance: none;
        background: none;
        border: 0;
        outline: 0;
        flex-grow: 0;
        background-color: #FFFFFF;
        transition: background 300ms;
        cursor: pointer;
      }


      /* Pseudo element for check styling */

      [type=checkbox]::before {
        content: "";
        color: transparent;
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        border: 0;
        background-color: transparent;
        background-size: contain;
        box-shadow: inset 0 0 0 1px #CCD3D8;
      }


      /* Checked */

      [type=checkbox]:checked {
        background-color: currentcolor;
      }

      [type=checkbox]:checked::before {
        box-shadow: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
      }


      /* Disabled */

      [type=checkbox]:disabled {
        background-color: #CCD3D8;
        opacity: 0.84;
        cursor: not-allowed;
      }


      /* IE */

      [type=checkbox]::-ms-check {
        content: "";
        color: transparent;
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        border: 0;
        background-color: transparent;
        background-size: contain;
        box-shadow: inset 0 0 0 1px #CCD3D8;
      }

      [type=checkbox]:checked::-ms-check {
        box-shadow: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
      }

    input[type="radio"] {
        /* hiding browser el */
        appearance: none !important;
        /* Safari support */
        border: 1px solid #fff;
        border-radius: 50%;
        outline: auto;
        color: #cfcfcf;
    }

    /* keyboard navigation focus appearance */
    input[type="radio"]:focus-visible {
        outline-offset: 0;
    }

    input[type="radio"] {
        box-shadow: 0 0 0 1px var(--color);
    }

    input[type="radio"]:hover {
        border-width: 0;
    }

    input[type="radio"]:checked {
        box-shadow: 0 0 0 1px var(--checked-color);
        border-width: 6px;
        background: #005FEC !important;
        color: #005FEC;
    }
  }

  .filterFooterControls{
    border-top: 1px solid #E3E3E3;
    button{
      background: transparent;
      color: grey;
      margin-top: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0px;
    }
    display: flex;
  }

  .group-link-small a {
    color: $blue-link;
    font-size: 16px;
    padding-left: 16px;
  }

  .group-link-medium a {
    color: $blue-link;
    font-size: 16px;
    padding-left: 20px;
  }

  .icon-custom-plus {
    color: $blue-link;
    padding-left: 18px;
  }

  hr {
    margin-bottom: 0;
    margin-top: 0;
  }
  .multi-select-item{
    border: unset !important;
  }

  input[type="search"].multi-select-search{
    margin: 1vh auto;
    width: 95% !important;
    margin-left: 15px;
    padding: 0px 0px 2px 25px;
    background-color: #fff !important;
    border: 1px solid #AAAAAA;
    border-radius: 4px !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAb1BMVEUAAADMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMwAAADh2V9oAAAAI3RSTlMAIJnl8bE8RvL9d58eDnX6Ul/ZAd0lQM8zPe4F5n/TFUHO255bFEkAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAAeklEQVQI122M2w6CMBAFF2iR+x2LIKDO//+jtCVGE8/LZCabFZEgjFA6lnOXBFQKmdcgIS+krGoa5yG5Y0vnGFE49gOjJer8o7laqLT0bph8rpzemBfLmLrtD72z+rsMBm2Abfeh6Q6Z1+0TZHxMi+xfwe1feMpPMK83LXsJvG5D+V0AAAAASUVORK5CYII=) !important;
    background-repeat: no-repeat !important;
    background-position: 2% !important;
    content-visibility: visible;
    mix-blend-mode: difference;
    backdrop-filter: opacity(0.5);
    fill-opacity: 8%;
  }
  input[type="search"].multi-select-search:focus-visible{
    outline: unset !important;
  }
}

.Popover-tipShape {
  fill: #fff;
}

.controls-popover {
  min-width: 300px;
  &.quality-controls-popover{
    max-width: 350px;
  }
  .Popover-body {
    background-color: #f7f7f7;
    border-radius: 1px;
  }

  .Popover-tipShape {
    fill: #f7f7f7;
  }
}
