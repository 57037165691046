.external-report {
  .custom-select-items li {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  #site-nav nav {
    width: 100% !important;

    .logo {
      margin-left: 20px;
    }
  }
}
