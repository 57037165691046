@import 'styles/mixins';

.analytics {
  .amcharts-export-menu-top-right {
    top: 0;
    opacity: 1;
    right: 0;
  }

  .panel-collapse-arrow {
    top: 10px;
    right: 0;
  }

  .analytics-charts {
    display: flex;
    justify-content: space-between;
    @include desktop() {
      flex-wrap: wrap;
    }
  }

  .col-half {
    display: inline-block;
    width: 50%;
    padding: 10px;

    @include desktop() {
      display: block;
      width: 100%;
    }
  }

  .col-full {
    width: 100%;
  }
}
