/**
 * Drawing mode
 */
.amcharts-export-canvas {
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.amcharts-export-canvas.active {
  display: block;
}

/**
 * Menu; Rest state
 */
.amcharts-export-menu {
  position: absolute;
  z-index: 2;
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  color: #000;
}

.amcharts-main-div:hover .amcharts-export-menu,
.amcharts-stock-div:hover .amcharts-export-menu {
  opacity: 1;
}

.amcharts-export-menu-top-left > ul > li > ul::after {
  content: "";
  position: absolute;
  top: 13px;
  right: 100%;
  z-index: 1000;
  border-top: 7px solid transparent;
  border-left: 7px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 7px solid transparent;
}

.amcharts-export-menu-top-left > ul > li > ul > li::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 100%;
  z-index: 1001;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid #e2e2e2;
  border-bottom: 8px solid transparent;
}

.amcharts-export-menu-top-right > ul > li > ul::after {
  content: "";
  position: absolute;
  top: 13px;
  left: 100%;
  z-index: 1000;
  border-top: 7px solid transparent;
  border-left: 7px solid #fff;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.amcharts-export-menu-top-right > ul > li > ul > li::after {
  content: "";
  position: absolute;
  top: 12px;
  left: 100%;
  z-index: 1001;
  border-top: 8px solid transparent;
  border-left: 8px solid #e2e2e2;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.amcharts-export-menu-bottom-left > ul > li > ul::after {
  content: "";
  position: absolute;
  bottom: 13px;
  right: 100%;
  z-index: 1000;
  border-top: 7px solid transparent;
  border-left: 7px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 7px solid transparent;
}

.amcharts-export-menu-bottom-left > ul > li > ul > li::after {
  content: "";
  position: absolute;
  bottom: 12px;
  right: 100%;
  z-index: 1001;
  border-top: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid #e2e2e2;
  border-bottom: 8px solid transparent;
}

.amcharts-export-menu-bottom-right > ul > li > ul::after {
  content: "";
  position: absolute;
  bottom: 13px;
  left: 100%;
  z-index: 1000;
  border-top: 7px solid transparent;
  border-left: 7px solid #fff;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.amcharts-export-menu-bottom-right > ul > li > ul > li::after {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 100%;
  z-index: 1001;
  border-top: 8px solid transparent;
  border-left: 8px solid #e2e2e2;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.amcharts-export-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.amcharts-export-menu li {
  position: relative;
  display: block;
  z-index: 1;
}

.amcharts-export-menu li > ul {
  position: absolute;
  display: none;
  border: 1px solid #e2e2e2;
  margin-top: -1px;
  background: #fff;
}

.amcharts-export-menu li > a {
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  padding: 12px;
  z-index: 2;
  white-space: nowrap;
  border-bottom: 1px solid #f2f2f2;
}

.amcharts-export-menu li:last-child > a {
  border-bottom: none;

}

.amcharts-export-menu li > a > img {
  border: none;
}

.amcharts-export-menu-top-left {
  top: 0;
  left: 0;
}

.amcharts-export-menu-bottom-left {
  bottom: 0;
  left: 0;
}

.amcharts-export-menu-top-right {
  top: 0;
  right: 0;
}

.amcharts-export-menu-bottom-right {
  bottom: 0;
  right: 0;
}

/**
 * Menu; Hover state
 */
.amcharts-export-menu li:hover > ul {
  display: block;
}

.amcharts-export-menu li:hover > a {
  color: #fff;
  background-color: #636363;
}

.amcharts-export-menu-top-left li:hover > ul {
  left: 100%;
  top: 0;
}

.amcharts-export-menu-bottom-left li:hover > ul {
  left: 100%;
  bottom: 0;
}

.amcharts-export-menu-top-right li:hover > ul {
  top: 0;
  right: 100%;
}

.amcharts-export-menu-bottom-right li:hover > ul {
  bottom: 0;
  right: 100%;
}

/**
 * Menu; custom class
 */
.amcharts-export-menu .export-main {
  margin: 4px;
}

.amcharts-export-menu .export-main > a li:last-child {
    margin-right: 30px;
}

.amcharts-export-menu .export-main > a,
.amcharts-export-menu .export-drawing > a {
  text-indent: -13333337px;
  width: 36px;
  height: 36px;
  padding: 0;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAQCAYAAADNo/U5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4M2Q5NDllYS1lMjE3LTQ3Y2QtYTU1Ni04MTQ3NmRjNWEwYWQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjJDMzE5OUFERDIwMTFFNEE3MUI4OTEyNEFENjEzQjQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjJDMzE5OTlERDIwMTFFNEE3MUI4OTEyNEFENjEzQjQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpiYjc1MTkyYi0yZTY4LTQ5YWUtYTgxOS01ZmM1OTI2YmVjNzYiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDoxODYwYzBkYy0xZDk5LTExNzgtYWI2Yi1lZWQ1ZWU2MWI5YmEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7MkIPxAAAAhUlEQVR42mIsKytjIBUwYRF7CMT/kfBDYjSRZdMg1FQCxO+gWAZNjQySHEgdAwtUogeIvwPxRCy2g/h8QJwPxFPRnQcSCAPiH2iafkDFp+Ly0zogdgPi91D+eyh/HaGAOAzEtkB8DEofRlfAgiOArgKxNVWDHGbTfxL0MFJkEyMpmgACDADF7RquHJcLdAAAAABJRU5ErkJggg==);
  background-color: #fff;
  background-position: center;
  -webkit-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  margin: 8px 8px 0 10px;
}

.amcharts-export-menu .export-drawing > a {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTJBQjFEODJEQUFBMTFFNEI4MTNBOENFRThBOEEwQzgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTJBQjFEODNEQUFBMTFFNEI4MTNBOENFRThBOEEwQzgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozRTcyRDlGRkRBQTkxMUU0QjgxM0E4Q0VFOEE4QTBDOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozRTcyREEwMERBQTkxMUU0QjgxM0E4Q0VFOEE4QTBDOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmmQcJUAAAD2SURBVHjaYiwrK2MgAugD8Xog/gjEIUB8FyTIRIRGPSDeDcSKQGwAxIeBWI0YzSAb9wCxKJKYJBDvBWIVJgIad6NphAEZIF6FS7MBFhvRAT8TDo0gG0XwaAQFXDi6ZkOojYQ0ugDxGRYkQVmoRiFiNKKHdioQHwDiQCD+TUgjCMBsBhmSAMSRQHwUiK8AsS6SPIZGZJs9gPg/EB+D8o2gKQkEPmDTiKw5GYjlgLgPiBmB2AqIo6EaXbFphGkWA2IfKL8AiB8C8WogvgGNtjO4Qg/kp3ggZoMG0jYgngXEO4H4L6FED9JsDcQVQLwAiF8ykAAAAgwACUIvtAo9owUAAAAASUVORK5CYII=);
}

.amcharts-export-menu .export-main:hover,
.amcharts-export-menu .export-drawing:hover {
  padding-bottom: 100px;
}

.amcharts-export-menu.amcharts-export-menu-bottom-left .export-main:hover,
.amcharts-export-menu.amcharts-export-menu-bottom-left .export-drawing:hover,
.amcharts-export-menu.amcharts-export-menu-bottom-right .export-main:hover,
.amcharts-export-menu.amcharts-export-menu-bottom-right .export-drawing:hover {
  padding-bottom: 0;
  padding-top: 100px;
}

.amcharts-export-menu .export-main:hover > a {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAQCAYAAADNo/U5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3BpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4M2Q5NDllYS1lMjE3LTQ3Y2QtYTU1Ni04MTQ3NmRjNWEwYWQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEQ4NjQ4NDlERDI3MTFFNEE1RThDQkM0MTU3NkJCQUIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEQ4NjQ4NDhERDI3MTFFNEE1RThDQkM0MTU3NkJCQUIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGMkMzMTk5OUREMjAxMUU0QTcxQjg5MTI0QUQ2MTNCNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMkMzMTk5QUREMjAxMUU0QTcxQjg5MTI0QUQ2MTNCNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PrFJGbMAAAB9SURBVHjaYvz//z8DqYAJi9hDIP6PhB8So4ksmwaRJgZQ6AFxCRC/g+K//1HBXyS5EnBoQzWBcDYQ//mPHfyByjOgawLhICD+jqbhO1ScAZcmELaFOuU/lLZFV4NNEwhrA/FRKI0hz0itZEQQsMBCngQ9jBTZxEiKJoAAAwDA0OlvgOoZkQAAAABJRU5ErkJggg==);
}

.amcharts-export-menu .export-drawing:hover > a {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTJBQjFEODZEQUFBMTFFNEI4MTNBOENFRThBOEEwQzgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTJBQjFEODdEQUFBMTFFNEI4MTNBOENFRThBOEEwQzgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxMkFCMUQ4NERBQUExMUU0QjgxM0E4Q0VFOEE4QTBDOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxMkFCMUQ4NURBQUExMUU0QjgxM0E4Q0VFOEE4QTBDOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Prwb/VYAAADaSURBVHjaYvj//z8DEVgfiO8B8XkgVoaJE6NRD4hf/UeAZ0CsRoxmfTSNMPAYiFXI0QgD55gYsAMDIN4DxKIMuAE/NhsNgPj1f/zgAxCboGs0BOI3xGhEDzBZIH5LrEYQRvZzKhAfAOJAIP6NxY8fgdgFiM/ARaCmMAHxIyC2hvLPAfFvXDaiJxIvIH4IxIxIkv5Qje+xaUTWvBaqsB9qgBUQr8KnEaZZDIh/IjkR5PynQNwExPL4UiCIKIVq+gXEG6BeYCYmw4CcuAEYbseBeAEQv2QgAQAEGACNAGwIYqHBngAAAABJRU5ErkJggg==);
}

/**
 * Menu; Color picker
 */

.amcharts-export-menu .export-drawing-color {
  background: #000;
  width: 35px;
}

.amcharts-export-menu .export-drawing-color a {
  display: block;
  overflow: hidden;
  text-indent: -99999999px;
}

.amcharts-export-menu .export-drawing-color-red {
  background: #f00;
}

.amcharts-export-menu .export-drawing-color-green {
  background: #0f0;
}

.amcharts-export-menu .export-drawing-color-blue {
  background: #00f;
}

.amcharts-export-menu .export-drawing-color-white {
  background: #fff;
}

.col-full .amcharts-export-menu-top-right {
  margin-right: 12px;
}

.amcharts-axis-title{
  font-weight:400;
}

.endpoints_by_device_and_version, .total_count_endpoints , .col-full .end_points {
  .amcharts-export-menu-top-right {
    margin-right: 12px;
  }
}

