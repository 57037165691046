@import '@nextiva/next-ui/styles/colors';

.overlayModal {
  z-index: 9;
}

.modal {
  position: relative;
  width: 540px;
  max-height: 80vh;
  overflow: hidden;
}

.player {
  position: relative;
  min-height: 165px;
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.cancel {
  margin-right: 20px;
}

.save {
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.nx-spinner-svg) {
    margin-right: 10px !important;
    position: initial;
    transform: none;
  }
}