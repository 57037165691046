.report-selector-btn {
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #c3c3c3;
  display: flex;
  color: #404040;
  font-size: 18px;
  padding: 8px 12px;

  .icon-custom-caret-down {
    font-size: 22px;
    margin-left: 6px;
    color: #c3c3c3;
  }
}

.report-selector-popover {
  .Popover-body {
    border-radius: 6px;
    padding-left: 0;
  }
}

.report-selector {
  display: flex;
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  &--row {
    display: flex;
    align-items: center;
  }
}

.item-container {
  &__section {
    width: 170px;

    &--left {
      border-right-style: solid;
      border-right-width: thin;
      border-color: #c3c3c3;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    color: #c3c3c3;
    padding-left: 20px;
    padding-bottom: 0;
  }

  &__links {
    list-style: none;
    font-size: 16px;
    padding-left: 0;
  }

  &__line {
    padding-bottom: 10px;
    padding-top: 10px;

    &:hover {
      background-color: #f1f1f1;
      color: #3d85d1;
      font-weight: 500;
    }
  }

  &__link {
    text-decoration: none;
    color: #404040;
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 20px;
  }
}
