@import 'styles/mixins';

.call-filters {
  display: flex;
  margin: 0;
  padding: 0;

  @include tablet() {
    margin: 20px -1% 10px;
  }

  .bold {
    color: #888 !important;
  }
  &.adoption-and-usage-filters{
    & > li:first-child{
      padding: 0 33px 0 0 !important;
    }
    & > li:last-child{
      padding:  0 0 0 33px !important;
    }
    & > li:nth-child(odd){
      padding:0 33px;
    }
    a{
      width: auto!important;
    }
  }

  a {
    display: block;
    text-decoration: none;
    padding: 10px 0;
    width: 90px;
    color: #333;

    @include tablet() {
      width: 100%;
    }
  }

  a:active {
    color: #333;
  }

  li {
    list-style-type: none;
    display: inline-block;
    cursor: pointer;
    margin-right: 4px;

    @include tablet() {
      &:not(.divider) {
        width: 18%;
        margin: 0 1%;
      }
    }
  }

  .active {
    background-color: #3d85d1 !important;
    border-radius: 3px;

    div {
      color: white !important;
    }
  }

  li div:first-child {
    font-size: 0.9em;
    margin-bottom: 2px;
    color: #c2c2c2;
    text-align: center;
  }

  li div:nth-child(2) {
    text-align: center;
    font-size: 0.9em;
  }

  .divider {
    position: relative;
    width: 2px;

    @include tablet() {
      width: 1%;
      margin: 0 1%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 38px;
      width: 2px;
      background-color: #efefef;
      transform: translate(-50%, -50%);
    }
  }
}
