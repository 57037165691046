@import 'styles/mixins';

.dashboard-content {
  flex: 1;

  &.gamification {
    margin-top: 20px;
  }

  @include tablet() {
    overflow-x: auto;
  }
}

.monitoring-dashboard-iconic .icon {
  height: 30px;
  width: 30px;

  @include desktop() {
    height: 25px;
    width: 25px;
  }

  path {
    fill: white !important;
  }
}

.monitoring-controls {
  .dashboard-popout {
    cursor: pointer;
  }
}

table.monitoring-dashboard-iconic {
  border-collapse: collapse;
  border-spacing: 9px;
  width: 100%;
  margin: 2em 0;
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  //table-layout: fixed;
  th {
    font-weight: 400;
  }

  thead td,
  thead th {
    min-width: 160px;
    height: 85px;
    font-weight: normal;
    border-right: solid white 10px;

    @include desktop() {
      min-width: 130px;
    }
  }

  thead th:first-child {
    width: 260px;

    @include desktop() {
      width: auto;
    }
  }

  tfoot > tr > td {
    height: 60px;
  }

  tfoot > tr > td div {
    position: relative;

    .superscript {
      padding-left: 3px;
      position: absolute;
      font-size: 14px;
    }
  }

  td:nth-child(n+3) {
    border-left: solid 1px $border;
  }

  .monitoring-dashboard-location {
    text-align: left;
  }
  //IPAD

  @include ipad-portrait() {
    td:first-child {
      font-size: 16px;
    }

    th:first-child {
      width: 200px !important;
    }

    th:nth-child(n + 1) {
      width: 140px;
    }
  }
}

.monitoring-dashboard-table {
  margin-bottom: 20px;
  overflow: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
  }
}

table.monitoring-dashboard-original {
  border-collapse: separate;
  border-spacing: 20px;
  width: 100%;
  margin: 2em 0;
  text-align: center;
  font-weight: 300;
  font-size: 28px;

  tr td:last-child div {
    position: relative;

    .superscript {
      top: 2px;
      padding-left: 3px;
      position: absolute;
      font-size: 14px;
    }
  }

  thead td,
  thead th {
    font-weight: normal;
  }

  td {
    white-space: nowrap;
  }

  .monitoring-dashboard-location {
    text-align: center;
  }
}

.monitoring-dashboard-location {
  border-collapse: separate;
  font-size: 24px;
  min-height: 60px;
  color: #333;
  height: 60px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.monitoring-dashboard-location::before {
  content: '';
  background: url(../img/location.png) center center no-repeat;
  display: inline-block;
  width: 19px;
  height: 26px;
  margin: 0 15px -1px 8px;
}

.monitoring-dashboard-username {
  border-collapse: separate;
  text-align: left;
  padding-left: 15px;
  font-size: 30px;
  min-height: 60px;
  color: #333;
  height: 60px;
  width: 185px;
}

th.dashboard {
  color: white;
}

th.monitoring-dashboard-avg-talktime {
  background: #fcd730;
  color: white;
}

th.monitoring-dashboard-talktime {
  background: #b3b3b3;
  color: white;
}

tr.monitoring-dashboard-talktime th,
tr.monitoring-dashboard-talktime td {
  border-bottom: 0.25em solid #b3b3b3;
}

th.monitoring-dashboard-outbound {
  background: #2a98d3;
  color: white;
}

td.monitoring-dashboard-outbound {
  color: #2a98d3;
}

tr.monitoring-dashboard-outbound th,
tr.monitoring-dashboard-outbound td {
  border-bottom: 0.25em solid #2a98d3;
}

th.monitoring-dashboard-inbound {
  background: #ffa72c;
  color: white;
}

td.monitoring-dashboard-inbound {
  color: #ffa72c;
}

tr.monitoring-dashboard-inbound th,
tr.monitoring-dashboard-inbound td {
  border-bottom: 0.25em solid #ffa72c;
}

th.monitoring-dashboard-answered {
  background: #b6e12f;
  color: white;
}

td.monitoring-dashboard-answered {
  color: #b6e12f;
}

tr.monitoring-dashboard-answered th,
tr.monitoring-dashboard-answered td {
  border-bottom: 0.25em solid #b6e12f;
}

th.monitoring-dashboard-missed {
  background: #fd2926;
  color: white;
}

td.monitoring-dashboard-missed {
  color: #fd2926;
}

tr.monitoring-dashboard-missed th,
tr.monitoring-dashboard-missed td {
  border-bottom: 0.25em solid #fd2926;
}

table.monitoring-dashboard-table td {
  border-collapse: separate;
  text-align: center;
}

table.monitoring-dashboard-table tbody .unit {
  display: none;
}

table.monitoring-dashboard-table tfoot .unit {
  vertical-align: super;
  line-height: 70%;
  font-size: 70%;
  margin-left: 0.2em;
  margin-right: -2em;
}

.dashboard-controls {
  margin-left: 0;
  clear: both;
  margin-bottom: 10px;
  padding: 5px 0 15px;

  > .chart-dropdown {
    padding: 8px 0;
  }

  > select {
    display: inline-block;
    vertical-align: top;
  }

  .react-select-box-container {
    width: 170px;
    margin-right: 10px;
  }

  .comparison-dropdowns select,
  > select {
    height: 40px !important;
    margin-right: 10px;
    width: 170px;
    font-size: 16px;
  }

  .chart-buckets {
    margin-left: 20px;
  }

  .date-picker {
    display: block;
    padding-top: 15px;
    margin-bottom: 20px;
  }

  .popout-button {
    vertical-align: bottom;
    position: relative;
    left: 18px;
    top: -1px;
  }
}
