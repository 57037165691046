.custom_dashboard_container {
  .dashboard_layout {
    min-height: 410px;
  }
}

.custom_dashboard_view_container {
  border-top: 1px solid $border;

  .date-range {
    padding: 10px;
  }
}

.custom_dashboard_date_range {
  display: flex;
  align-items: center;
  padding-top: 10px;

  .video {
    margin-left: auto;
  }

  .date-range {
    display: inline-flex;
  }

  .icon-custom-pencil {
    color: #bbb;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
  }

  .open-popout {
    display: inline-block;
    margin-left: 15px;
    cursor: pointer;
  }
}

.custom_dashboard_info {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border;
  padding: 20px 0;
}

.custom_dashboard_description {
  flex: 1;
  margin: 0 10px;
}

.custom_dashboard_tile {
  //* {
  //  box-sizing: border-box;
  //  -moz-box-sizing: border-box;
  //  -webkit-box-sizing: border-box;
  //}

  .custom_dashboard_tile_wrapper {
    height: inherit;
  }

  &.new {
    border: 2px dashed $blue;
  }

  .custom_dashboard_actions {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;

    > div {
      cursor: pointer;
    }

    > div:not(:last-of-type) {
      margin-right: 5px;
    }
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 0;
    cursor: pointer;
  }

  .edit {
    color: #bbb;
    font-size: 18px;
  }

  .dropzone-container {
    height: 90%;
    overflow: auto;
  }

  .chart-dropdown .option .inline-svg {
    padding-right: 40px;
  }
}

.custom_dashboard_tile_content {
  height: 100%;
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;

  input[type="text"],
  select {
    width: 100%;
  }

  .custom_dashboard_tile_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .custom_dashboard_tile_content_title {
      padding: 0;
    }

    .custom_dashboard_actions {
      position: relative;
      top: 0;
    }
  }

  .content_title {
    padding: 5px 0 15px;
  }

  &.monitoring-gamification {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .leaderboard-table,
    .row {
      margin-top: initial;
      min-height: initial;
      overflow: auto;
    }

    .row {
      margin: 0;
      height: 100%;
    }

    .leaderboard {
      min-height: auto;
    }

    .leaderboard-row-avatar {
      display: inline-block;
      background-color: #fff;

      img {
        width: 48px !important;
        height: 48px !important;
      }
    }

    .custom_dashboard_leaderboard {
      .place-content .circle {
        font-size: 20px;
        height: 60px;
        line-height: 2.6;
        width: 60px;

        sup {
          font-size: 15px;
        }
      }

      img {
        height: 20px;
        width: 20px;
      }

      .leaderboard-row .leaderboard-row-content {
        height: 50px;

        .leaderboard-row-name {
          font-size: 20px;
          padding: 15px 0;
        }

        .leaderboard-row-stats .border-icon .divider {
          height: 35px;
        }

        .leaderboard-row-stats .counter-section > span {
          font-size: 12px;
        }
      }

      .count {
        height: 33px;
        line-height: 34px;
        width: 24px;

        span {
          font-size: 35px;
        }
      }
    }

    .table {
      margin: 10px 0;
    }

    .leaderboard .leaderboard-row .leaderboard-row-content .leaderboard-row-stats .counter-section > span {
      top: -3px;
    }
  }

  .amcharts-chart-div {
    overflow: visible !important;

    & > svg {
      overflow: visible;
    }

    .amcharts-balloon-div {
      overflow: visible;
    }
  }
}

.custom_dashboard_tile_content_title {
  padding: 5px 0 15px;
}

.custom_dashboard_tile_content_wrapper {
  flex: 1;
}

.custom_dashboard_subtitle {
  color: #aaa;
  padding: 10px 0;
}

.custom_dashboard_tile_buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  .btn_trash {
    padding: 5px 10px;
  }

  .btn:not(:last-child) {
    margin-right: 20px;
  }
}

.custom_dashboard_popover {
  .custom_dashboard_popover_title {
    padding: 10px 0;
    border-bottom: 1px solid $border;
  }

  .custom_dashboard_popover_content {
    // max-width: 300px;
    padding: 10px 5px;

    input[type="text"],
    select {
      width: 100%;
    }

    .content_title {
      padding: 5px 0 15px;
    }

    .infinity {
      width: 75px;
      color: #999;
      font-size: 24px;
      margin: 0 10px;

      span {
        margin-left: 10px;
      }
    }

    .btn:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  select {
    background-position: 98.5% 50%;
  }

  .react-select-box-container {
    width: 100%;
  }
}

.call_type_threshold_container {
  display: flex;
  flex-direction: column;
  padding: 15px;

  .threshold_buttons {
    display: flex;
    justify-content: flex-end;
  }

  .btn_add,
  .btn_remove {
    align-self: flex-end;
    border-radius: 50%;
    padding: 9px 10px;
  }

  .btn_add {
    margin-left: 10px;
  }

  .threshold_add,
  .threshold_remove {
    height: 15px;
    width: 15px;
    position: relative;
    top: 1px;

    path {
      fill: black !important;
    }
  }
}

.call_type_threshold {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .threshold-from,
  .threshold-to {
    margin: 0 10px;
    width: 75px !important;
  }
}

.call_type_results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  p {
    margin: 10px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.custom-dashboard__tile {
  &--new {
    border: 2px dashed $blue;
  }

  &__content {
    height: 100%;
  }

  &__actions {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;

    > div {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  .chart {
    height: calc(100% - 33px); //TODO: need to rewrite to flex, 33px - chart header
    overflow: hidden;

    & > div {
      height: 100%;
    }
  }
}

.custom-dashboard-controls-title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding: 10px;
}
