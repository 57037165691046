$search_toggle_color: #aaa;

input[type="search"].multi-select-search {
  background-color: $search_toggle_color;
  border-color: transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #fff;
  height: 40px;
  margin-left: 10px !important;
  width: 276px !important;
  cursor:auto;

  &::-webkit-search-cancel-button {
    margin-right:20px;
    cursor: pointer;
  }

  &::-webkit-input-placeholder {
    color: #fff;
  }

  // scss-lint:disable PseudoElement
  &:-moz-placeholder { /* Firefox 18- */
    color: #fff;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    color: #fff;
  }

  &:-ms-input-placeholder {
    color: #fff;
  }
}

.multi-select-toggle {
  background: none;
  background-color: $search_toggle_color;
  border: 0;
  color: #fff;
  height: 40px;
  width: 100%;
}

.multi-select-items {
  margin: 0;
  max-height: 250px;
  padding: 4px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4.01px;
  }
  &::-webkit-scrollbar-thumb {
    background: #E3E3E3;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.multi-select-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid $border;
  }

  .standard-checkbox {
    width: 15%;
  }

  .multi-select-label {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #666666;
    text-align: left;
    letter-spacing: 0px;
  }
}

.call-filter-buttons {
    background: none;
    background-color: white;
    border: 0;
    color: #005FEC;
    height: 40px;
    width: 100%;
    &.disabled {
        color: grey
    }
    }
