@import 'styles/mixins';

.scheduled-report-form {
  $section-height: 250px;
  $section-padding: 8px 0 16px 0;
  $label-padding: 10px 0;
  $field-margin: 30px;

  &__field {
    display: flex;
    flex-flow: column nowrap;

    select {
      margin-right: 10px;
      min-width: 70px;
      width: 100%;
    }

    & + & {
      margin-left: 10px;
    }

    &--schedule-name {
      flex: 1 1 0%;
    }

    &--report-name {
      flex: 2 1 0%;
    }

    &--timezone {
      flex: 1 1 0%;
      padding-left: 15px;

      @include tablet() {
        padding-left: 0;
      }
    }

    &--weekdays {
      margin-right: $field-margin;
    }

    &--period {
      flex: 1 1 0%;
      margin-right: $field-margin;

      @include desktop() {
        margin-right: 20px;
      }

      @include tablet() {
        margin-right: 0;
      }
    }

    .scheduled-report-form__distribution &:first-of-type,
    .scheduled-report-form__distribution & + & {
      margin-left: initial;
      margin-right: $field-margin;
    }
  }

  &__label {
    color: $input-border;
    display: inline-block;
    padding: $label-padding;
  }

  &__radio {
    display: flex;
    padding-top: 10px;

    &--reporting {
      flex: 1 1 0%;
    }

    label {
      margin-left: 5px;
    }
  }

  &__buttons {
    padding: 40px 0 20px;
  }

  &__section {
    display: flex;
    flex-flow: row nowrap;
    height: auto;
  }

  &__section-heading {
    display: flex;
    flex-flow: row nowrap;
    padding: $section-padding;

    @include tablet() {
      width: 160px;
    }
  }

  &__section-title {
    padding: $label-padding;
  }

  &__section-content {
    border-top: $border 1px solid;
    flex: 5 1 0%;
    padding: $section-padding;

    .scheduled-report-form__section--first & {
      border: 0;
    }
  }

  &__button {
    padding: 2px 6px;

    &--add-time {
      margin: 26px 0 16px;
    }
  }

  &__scheduled {
    display: flex;
    flex-flow: row nowrap;
  }

  &__reporting {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;

    @include tablet() {
      display: block;
    }

    &-sections {
      display: flex;
      flex: 3 1 0%;
      flex-flow: column nowrap;
    }

    &-section {
      flex: 1 1 auto;
      margin-bottom: 6px;
    }
  }

  &__interval {
    display: flex;
    align-items: center;

    span + select {
      margin-left: 10px;
    }

    select + span {
      margin-left: 10px;
    }
  }

  &__fixed {
    display: flex;
  }

  &__fixed-dates {
    display: flex;
    flex: 3 1 0%;
    flex-flow: column nowrap;
  }

  &__relative {
    display: flex;
  }

  &__relative-dates {
    display: flex;
    flex: 3 1 0%;
    flex-flow: column nowrap;

    .standard-checkbox {
      margin: 10px 0;
      padding-left: 0;

      input {
        margin: 0 10px 0 0;
      }
    }
  }

  &__relative-units {
    align-items: center;
    display: flex;
  }

  &__time-range {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include tablet() {
      width: 470px;
    }

    span + span {
      margin-left: 10px;
    }
  }

  &__weekdays {
    align-items: center;
    display: flex;

    .weekday-checkboxes {
      align-self: flex-end;
      margin-left: 20px;
    }
  }

  &__distribution {
    display: flex;
    flex-flow: row wrap;
  }

  &__distribution-times {
    display: flex;
    flex-basis: 100%;
    flex-flow: row wrap;
  }

  &__hour-minute {
    align-items: center;
    display: flex;

    .scheduled-report-form__distribution-time & + .scheduled-report-form__button {
      margin-left: 10px;
    }

    span + span {
      margin-left: 10px;
    }

    &--distribute-time {
      align-self: flex-end;
      margin-right: $field-margin;
    }
  }

  &__distribution-time {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    margin-right: 20px;
    padding: 20px 0 10px;
  }

  &__monthly {
    flex-basis: 100%;
  }

  &__monthly-option {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    padding: 10px 0;

    select {
      margin: 0 10px;
    }

    select + select {
      margin-left: 0;
    }
  }

  &__tip {
    display: flex;
    align-items: center;
    height: 40px;
    font-style: italic;
    color: #999;
    font-size: 16px;

    &--period {
      align-self: flex-end;
      margin-left: 10px;
    }
  }
}
