@import '@nextiva/next-ui/styles/colors';

.additional {
  text-align: left;
  font-size: 16px;

  :global {
    .react-tabs__tab {
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 40px;
      text-align: left;
      padding: 0 10px;

      &:last-child {
        margin-right: 0;
        margin-left: auto;

        svg {
          margin-left: 0;
          margin-right: 10px;
        }
      }

      svg {
        margin-left: 10px;
        width: 16px;
        height: 16px;

        path {
          fill: color(graphite);
        }
      }

      &-list {
        display: flex;
        height: 40px;
      }

      &-panel {
        position: relative;
        padding: 20px 0;
        min-height: 150px;
        box-sizing: border-box;
      }
    }
  }
}

.note {
  :global(.nx-textarea__input) {
    min-height: 100px;
    max-height: 200px;
    resize: auto;
  }
}

.questions {
  :global(.recording-no-questions-groups) {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 0;
    transform: translateY(-50%);
  }
}
