@import 'styles/colors';
@import 'styles/mixins';

.teams-container {
  .new-team {
    font-size: 18px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid $border;

    h1 {
      display: flex;
      align-items: center;
      margin: 0;
    }

    span {
      margin-left: 10px;
    }
  }

  .new-team-plus {
    height: 15px;
    width: 15px;
    position: relative;
    left: 8px;
    top: 1px;

    path {
      fill: white !important;
    }
  }

  .manage-team .title {
    margin-bottom: 25px;
  }

  .manage-team-info-container {
    margin-left: 280px;

    .toggle-label {
      display: flex;
      justify-content: flex-end;
    }
  }

  .manage-team-info-inputs {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border;
    padding-bottom: 25px;

    @include tablet() {
      flex-wrap: wrap;
    }
  }

  .manage-team-info-name {
    flex: 1;

    @include tablet() {
      flex: auto;
      width: 100%;
    }
  }

  .manage-team-info-description {
    flex: 2;
    margin: 0 15px;

    @include tablet() {
      flex: auto;
      margin: 15px 0 0;
      width: 100%;
    }
  }

  .manage-team-info-filter {
    flex: 1;

    @include tablet() {
      flex: auto;
      margin-top: 15px;
      width: 100%;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }

  .data-table {
    margin-bottom: 20px;
  }
}
