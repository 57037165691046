.real-time {
  color: #888;
  font-size: 0.95em;
  display: inline-block;
  width: 105px;

  @include ipad() {
    width: 70px;
  }

  @include ipad-portrait() {
    margin-bottom: 10px;
  }

  input {
    position: relative;
    top: 4px;
    margin-right: 10px;
  }
}
