.attention_table_row, .attention_table_header {
    border: 2px solid #F7F8FA;
    height: 50px;
    .single_table_row{
      font-weight: 400;
      &.poor_value{
        font-weight: 700;
      }
    }
}

table {
    width: 100%;
}

.attention_div {
    .header_div{
        display: flex;
        justify-content: space-between;
        margin-bottom:10px;
        font-size: 16px;
        color:#666666;
        font-weight: 400;
    }

    .legend_div {
      align-items: center;
      display: flex;
    }
}

.attention_location_header {
    margin:0px;
    padding:10px;
    background-color: #F7F8FA;
    font-weight: 400;
}

.attention_table{
    overflow-y:scroll;
    max-height:550px;
}

.single_table_header, .single_table_row {
    width: 25%;
    text-align: left;
    padding-left:15px;
}

.single_table_header {
    font-weight: 700;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700 bold;
    line-height: 22px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #666666;


}

.good_value {
  color: #5FCFC1;
  font-weight: 400;
}

.moderate_value{
    color:#F19D3C;
    font-weight: 400;

}

.poor_value {
  color: #D7563C;
    font-weight: 400;
}

.legend {
  height: 15px;
  width: 15px;
  border-radius: 1px;
  margin:0 6.5px 0 14px;
  font-family: Roboto;
}

.good_legend{
    background-color:#5FCFC1;
}

.moderate_legend {
  background-color: #F19D3C;
}

.poor_legend {
  background-color: #D7563C;
}