@import '@nextiva/next-ui/styles/colors';

.info {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 16px;
}

.item {
  display: flex;
  align-items: center;
  color: color(nextiva-gray);
}

.icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;

  :global(path) {
    fill: color(gray);
  }
}