.tabbed-controls-tabs {
  display: flex;
  margin: 0;
  line-height: 32px;
  list-style: none;
  padding: 0;
  position: relative;
  text-align: center;
}

.tabbed-controls-tab {
  display: list-item;
  align-items: center;
  background-color: #f7f7f7;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #ccc;
  cursor: pointer;
  margin: 1px 10px;
  outline: none;
  padding: 11px 20px;
  position: relative;
  z-index: 0;

  &.selected {
    background-color: #fff;
    border: 1px solid #ececec;
    border-bottom: 2px solid transparent;
    color: $blue;
    margin: 0 10px;
    margin-bottom: -1px;
    z-index: 2;
  }
  .popup-filter-header{
    width: 37px;
    padding: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #666666;
  }
  .tabbed-controls-tab-icon{
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #758BA3;
  }
}

.tabbed-controls-content {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ececec;
  height: auto;
  overflow: hidden;
  display: none;
  padding: 3px;

  &.react-tabs__tab-panel--selected {
    display: block;
  }

  .list-select {
    max-height: 290px;
    overflow: auto;
  }

  .multi-select-search {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }


  [type=checkbox] {
    color: #005FEC !important;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none !important;
    border: 1px solid #AAAAAA !important;
    outline: 0;
    flex-grow: 0;
    background-color: #AAAAAA;
    transition: background 300ms;
    cursor: pointer;
  }


  /* Pseudo element for check styling */

  [type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
  }


  /* Checked */

  [type=checkbox]:checked {
    background-color: #005FEC !important;
  }

  [type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }


  /* Disabled */

  [type=checkbox]:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
  }


  /* IE */

  [type=checkbox]::-ms-check {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
  }

  [type=checkbox]:checked::-ms-check {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }
}
