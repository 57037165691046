.list-select {
  list-style: none;
  padding: 0 25px;
}

.list-select-option {
  cursor: pointer;
  padding: 15px 20px;

  &.selected {
    font-weight: bold;
  }

  &:hover {
    background-color: #666;
    color: #ececec;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $border;
  }
}
