@import '~@nextiva/next-ui/styles/colors';
@import 'styles/mixins';

.noData {
  text-align: center;
}

.icon {
  width: 32px;
  height: 32px;
  fill: color(brand-yellow);
}

.description {
  margin: 10px 0 0;
  font-size: 28px;
  color: color(nextiva-gray);
}

.absolutePosition {
  @include center-position();
}
