.button-popover-container {
  .btn {
    display: flex;
    align-items: center;
    border-radius: 2px;
    height: 21px;
    padding: 7px 10px;
  }

  .btn-transparent {
    display: flex;
    padding: 0 10px;
  }

  .icon-custom-caret-down {
    font-size: 22px;
  }
}

.button-popover-icon {
  cursor: pointer;
}

.button-popover-group {
  display: flex;
  flex-flow: row nowrap;

  .button-popover-container {
    margin-right: 10px;
  }
}

.button-popover {
  z-index: 1000;
}
