@import '@nextiva/next-ui/styles/colors';

.error {
  padding: 10px 0;
  font-size: 16px;

  :global(.nx-service-unavailable-placeholder) {
    &__title {
      font-size: 24px;
    }
  }
}

.canvas {
  position: relative;
  min-height: 120px;
  margin: 0 10px;

  & > :global(canvas) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.controls {
  padding: 0 10px;
  opacity: 1;
  visibility: visible;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.clipEnd,
.clipStart {
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 10px;
  height: 100%;
  width: 1px;
  background: color(brand-blue);
  z-index: 1;
}

.line {
  position: relative;
  height: 1px;
  padding: 5px 0;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    height: 1px;
    background: color(cool-gray-1);
    transition: background .3s ease;
  }
  
  &:hover {
    &:before {
      background: color(smoke);
    }
  }
}

.progress {
  position: relative;
  top: -8px;
  left: -8px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-radius: 50%;
  background: #fff;
  transition: background .3s ease;
  box-shadow: 0 0 3px -1px #000;
  z-index: 1;

  &:active {
    background: color(cool-gray-1);
  }
}


.content {
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: color(gray);
  font: 400 12px lato;
}

.finishTime,
.progressTime {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.progressTime {
  left: 0;
}

.finishTime {
  right: 0;
}

.playButton {
  display: block;
  margin: 0 20px;
  width: 20px;
  height: 24px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent; 
  border-left: 20px solid color(nav-blue); 
  transition: all .2s ease;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;

  &.paused {
    border-style: double;
    border-width: 0 0 0 20px;
  }

  &:hover {
    border-color: transparent transparent transparent color(black-pearl);
  }
}

.replayIcon {
  position: relative;
  top: 1px;
  margin-right: 1px;
  width: 20px;
  height: 20px;
  opacity: .75;
  transition: opacity .3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  :global(path) {
    fill: color(nav-blue);
    transition: fill .3s ease;
  }

  &.active {
    opacity: 1;
    
    :global(path) {
      fill: color(havelock-blue);
    }
  }
}

.progressContainer {
  position: relative;
  margin: 0 20px;
  flex: auto;
  height: 12px;
  border-radius: 10px;
  background: color(black-pearl);
  cursor: pointer;
}

.progressLine {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 10px;
  background: color(green);
  transition: background .3s ease;
}

.selectedLine {
  position: absolute;
  height: 100%;
  border-radius: 10px;
  background: color(mercury);
}
