.color-themes {
  &__settings {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .preview {
    width: calc(100% - 340px);
    box-shadow: 0 0 5px #999;

    .barchart {
      height: 400px;
    }

    @include ipad-portrait() {
      width: 650px;
    }

    @include ipad-landscape() {
      width: 500px;
    }
  }

  .palette-container {
    label {
      cursor: pointer;
      display: inline-block;
      color: #666;
      margin-bottom: 10px;
      margin-left: 2px;
    }

    input[type=checkbox] {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }

    .palette {
      margin-left: 28px;
      margin-bottom: 40px;
    }
  }

  .settings {
    width: 280px;

    @include ipad-portrait() {
      margin-left: 50px;
    }
  }

  .color-block {
    height: 30px;
    width: 30px;
    margin-right: 7px;
    display: inline-block;
    border: 2px solid #fff;
    box-shadow: 0 0 1px $border;
  }

  .button-row {
    float: right;

    .btn {
      margin-right: 10px;
    }
  }
}
