/* The container <div> - needed to position the dropdown content */
.dropdown {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 100;
  height: 100%;

  /* Dropdown Content (Hidden by Default) */
  &__content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    margin-top: 44px;
    top: 0;
    left: 0;

    .dropdown:hover & {
      display: block;
    }
  }

  &__action {
    width: 100%;
  }

  /* Links inside the dropdown */
  &__action,
  &__link {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;

    /* Change color of dropdown links on hover */
    &:hover {
      background-color: #f1f1f1;
      color: #3d85d1;
    }
  }
}
