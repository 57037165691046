.react-datepicker__triangle {
  margin-top: -8px;
  margin-left: -8px;
}

.react-datepicker {
  @extend %noselect;
}

.react-datepicker__triangle,
.react-datepicker__triangle::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker__triangle::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  top: -1px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.react-datepicker__container {
  position: absolute;
  display: inline-block;
  z-index: 2147483647;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__current-month {
  color: black;
  font-weight: bold;
  font-size: 13px;
}

.react-datepicker__navigation {
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 6px;
  width: 0;
  border: 10px solid transparent;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__day-name {
  color: #ccc;
  display: inline-block;
  width: 28px;
  line-height: 24px;
}

.react-datepicker__month {
  margin: 5px;
  text-align: center;
}

.react-datepicker__day {
  color: #000;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  text-align: center;
  padding: 2px;
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 4px;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--selected {
  border-radius: 4px;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input:focus {
  outline: none;
}

.react-datepicker__tether-element {
  z-index: 1010;
}
