.filters-container {
  float: left;
  width: 230px;
  border: solid 1px $border;
  background-color: #fcfcfc;
  padding: 10px;
  min-height: 500px;

  &::after {
    @extend .clearfix;
  }

  .accordion__wrap {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__title {
    font-size: 20px;
    margin: 0;
    font-weight: 300;
  }

  &__search {
    margin-top: 10px;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    color: #777;
    width: 195px;
  }

  &__select {
    background-color: white;
    width: 227px;
    margin-top: 10px;
  }
}
