.monitoring-controls {
  margin-top: 10px;
  display: flex;
  align-items: center;

  .react-select-box-container {
    vertical-align: top;
  }

  & > div {
    margin-right: 10px;
  }

  .real-time {
    width: 70px;
  }

  & > select {
    height: 40px;
    width: 170px;
    font-size: 16px;
    margin-right: 10px;
  }
}

.type-combo-filter {
  & > div {
    display: inline-block;
  }

  select {
    height: 40px;
    width: 160px;
    font-size: 16px;
    margin-right: 10px;
  }
}

.standard-checkbox {
  padding: 0 0 0 10px;
}

.monitoring-no-data {
  @extend %center-all;

  margin: 50px;
  font-size: 40px;
  color: #777;
  text-shadow: #333;
}
