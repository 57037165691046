.analytics-call-duration {
  .multi-pie-container {
    overflow: visible;
  }

  .amChartsLegend {
    z-index: 10;
  }

  .chart-title-container {
    overflow: hidden;
    width: 100%;
    margin-top: 10px;
  }

  .large-pie-container {
    height: 410px;
    margin-bottom: 50px;
  }

  .large-pie {
    height: 600px;

    .amcharts-chart-div {
      position: relative;
      top: -60px;
    }

    .amcharts-legend-div {
      position: static !important;
    }
  }

  .amcharts-chart-div {
    overflow: visible !important;
  }

  .small-pie-container {
    margin-top: 20px;
    text-align: center;
  }

  .small-pie {
    margin-top: 20px;
    height: 400px;
    width: 120%;
    overflow: visible !important;

    .amcharts-chart-div {
      position: relative;
      top: -60px;
      left: -8%;
    }
  }
}
