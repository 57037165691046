.btn {
  @extend %noselect;

  cursor: pointer;
  display: inline-block;
  padding: 9px 20px;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
}

.btn-green {
  background-color: $green;
  color: white;
  border: 1px solid transparent;
}

.btn-blue {
  background-color: $blue;
  color: white;
  border: 1px solid transparent;
}

.btn-red {
  background-color: $darkRed;
  color: white;
  border: 1px solid transparent;
}

.btn-transparent {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  outline: none;
  overflow: hidden;
}

.buttons.pull-right {
  div,
  button {
    margin-right: 12px;
  }
}

.buttons.pull-right {
  div:last-child,
  button:last-child {
    margin-right: 0;
  }
}

.buttons.pull-left {
  div,
  button {
    margin-left: 12px;
  }
}

.buttons.pull-left {
  div:last-child,
  button:last-child {
    margin-left: 0;
  }
}
