@import 'styles/mixins';


.anErrorPlaceholder {
  margin: 50px 0;
  text-align: center;
}

.absolutePosition {
  margin: 0;
  @include center-position();
}