.popout {
  height: 100vh;
  width: 100%;
  border: none;
  margin: 0;
  box-sizing: border-box;

  > .content-box {
    margin: 0;
    padding: 0;
    border: 0;

    &::before {
      position: fixed;
    }
  }

  .content-box-header {
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  .chart {
    padding-right: 15px;
  }

  .left-side {
    display: inline-flex;
    align-items: center;

    .date-range {
      margin-left: 10px;
    }
  }

  .chart-header {
    padding: 20px;

    .date-range {
      display: inline-flex;
    }
  }

  .content-box-header,
  .chart-header {
    position: relative;
    z-index: 2;

    > span {
      padding: 0 20px;
    }

    .labels {
      padding: 20px 20px 0;
    }

    > .pull-right {
      padding-top: 5px;
    }

    .react-select-box-container {
      display: inline-block;
      margin-right: 20px;
    }

    .comparison-dropdowns {
      display: inline-block;
      clear: both;

      select {
        width: 160px;
        margin-right: 20px;
        display: inline-block;
      }
    }

    .button-popover-container {
      display: inline-block;
      margin-left: 5px;
    }

    .button-popover-icon {
      font-size: 14px;
    }
  }

  .amChartsLegend {
    padding: 19px 0 15px;
    height: auto;
  }

  .chart-container {
    height: 90%;
    margin: 0;
    padding: 0;
    min-height: 500px;
    overflow-y: hidden;

    .chart {
      height: 85%;

      & > div {
        height: 100%;
      }
    }

    &.border {
      border: none;
    }
  }

  .scatterchart {
    .amcharts-main-div {
      height: 100%;

      .amcharts-chart-div {
        height: 100% !important;
      }
    }
  }

  .piechart {
    .amcharts-div {
      height: 100%;
    }
  }

  .barchart {
    .amcharts-main-div {
      height: 100%;
    }
  }

  .linechart {
    .amcharts-main-div {
      height: 100%;

      .amcharts-chart-div {
        height: 100%;
      }
    }
  }

  .monitoring-dashboard-by-call {
    position: absolute;
    top: 0;
    font-size: 36px;
    line-height: 32px;
    border-spacing: 28px;
    margin: 0 auto;
    width: 100%;
    min-width: 1100px;
    height: 100%;
    padding: 115px 0;

    thead {
      line-height: 162px;
    }

    tbody {
      tr {
        padding: 0;
      }
    }
  }

  .superscript {
    font-size: 17px !important;
  }

  .monitoring-leaderboard-table-controls {
    padding: 18px 0 0 18px;
  }

  .monitoring-dashboard-by-location {
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 0;
    font-size: 36px;
    line-height: 32px;
    border-spacing: 28px;
    margin: 180px 0 0;
    width: 90%;
    min-width: 1100px;
    height: 100%;
    padding: 200px 0;
    left: 50%;
    transform: translateX(-50%);

    thead {
      th {
        height: 150px;
      }

      .icon {
        width: 30px;
        height: 30px;
      }
    }

    tbody > tr td:first-child {
      padding-left: 30px;
    }

    tfoot > tr > td {
      height: 141px;
    }
  }

  &.wallboard {
    .content-box-content {
      position: relative;
      width: 100%;
      min-width: 1200px;
      margin: -44px 0 0;
      padding: 0;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);

      .row {
        margin: 0 0 0 56px;

        .board-container {
          height: 326px;
          padding: 20px 0 20px 20px;

          .icon {
            width: 32px;

            @media screen and (min-width: 1230px) {
              width: 33px;
            }

            @media screen and (min-width: 1390px) {
              width: 35px;
            }

            @media screen and (min-width: 1540px) {
              width: 38px;
            }

            @media screen and (min-width: 1700px) {
              width: 39px;
            }
          }

          .board-container-title {
            top: -6px;

            @media screen and (min-width: 1230px) {
              font-size: 24px;
              top: -9px;
            }

            @media screen and (min-width: 1390px) {
              font-size: 28px;
              top: -6px;
            }

            @media screen and (min-width: 1540px) {
              font-size: 33px;
            }

            @media screen and (min-width: 1700px) {
              font-size: 36px;
            }
          }

          .board-container-value {
            margin: 0;
            font-size: 80px;

            @media screen and (min-width: 1700px) {
              font-size: 90px;
            }
          }

          .board-container-unit {
            font-size: 40px;
          }

          > div:first-child {
            padding-left: 5px;
          }

          > div:last-child {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            text-align: center;
            margin-top: -31px;
          }
        }
      }
    }
  }
}

.popout .monitoring-gamification {
  .content-box-content {
    margin-top: 0;
    padding: 20px 50px 50px;
  }
}

.popout .monitoring-controls {
  margin-top: 0;
  margin-left: 20px;
}

.popout .monitoring-dashboard-table {
  padding: 20px;
}

.popout .dashboard-controls {
  position: relative;

  .collapse-button {
    top: 15px;
  }

  .content-box-content {
    width: 100%;
    padding: 0;
  }

  .collapseable__open {
    overflow: inherit !important;
  }
}

.popout .wallboard {
  padding: 20px;
  box-sizing: border-box;
}

.popout .table-panel {
  overflow: hidden;
  padding: 20px;
}
